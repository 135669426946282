:root {
  --blue: #4a90e2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #fa5a5d;
  --red-dark: #98100f;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #cbf2f2;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #fb9a00;
  --secondary: #30cdcc;
  --success: #28a745;
  --info: #4a90e2;
  --warning: #ffc107;
  --danger: #DB0200;
  --light-3: #dce0e3;
  --light-2: #e7edf2;
  --light-1: #eff4f8;
  --active: #cbf2f2;
  --selected: #30cdcc;
  --table-row-bg: #f9f9f9;
  --placeholder: #dfdcdf;
  --form-text: #817f80;
  --header-text: #6b6b6b;
  --dark: #817f80;
  --link: #4a90e2;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "HelveticaNeue-Light", "Helvetica Neue Light",
    "Helvetica Neue Medium", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family-sans-serif);
  color: var(--dark);
  font-size: 14px;
  line-height: 1.5;
  color: #817f80;
  transition: all ease 0.3s;
}

.main-site {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  color: var(--info);
}

a:focus {
  box-shadow: 0 !important;
}

.text-link {
  color: var(--info) !important;
  font-weight: bold;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f9f9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  cursor: pointer;
}

*:hover::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
  cursor: pointer;
}

/*-- Element - Customzied --*/
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1220px;
}

@media screen and (min-width: 991px) {
  .floating-label.undefined {
    margin-bottom: 18px;
  }

  .pot--rev-col {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .int--in-col {
    flex: 0 0 15.3333%;
    max-width: 15.333%;
  }

  .card-body .form-group {}

  .main-card,
  .main-card .card {
    height: 100%;
  }

  .modal-medium>.modal-dialog {
    max-width: 700px;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 20px !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 20px !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 30px !important;
  }
}

.row {
  margin-right: -10px;
  margin-left: -10px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

.booking_location {
  padding: 22px 60px 22px 22px !important;
  word-break: inherit !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #818186;
  content: ">";
}

.breadcrumb .breadcrumb-item a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-colors-grey-500, #818186);
}

.modal-header {
  padding: 1.5rem 2rem 1rem;
}

.modal-body {
  padding: 1rem 2rem 0;
}

.modal-footer {
  padding: 0 2rem 1rem;
}

.modal-title {
  font-size: 18px;
  line-height: 28px;
  color: #121213;
  font-weight: 700;
}

.modal-title span {
  font-size: 16px;
  line-height: 26px;
  margin-top: 3px;
}

.custom-control {
  min-height: 20px;
  padding-left: 32px;
  display: inline-flex;
  align-items: center;
}

.custom-control-label {
  min-height: 20px;
  margin-top: 5px;
}

.custom-control-label::after {
  height: 20px;
  width: 20px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #30cdcc;
  background-color: #fff;
  border-radius: 0px;
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -31px;
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-checkbox .custom-control-label::before {
  position: absolute;
  top: 0;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: none;
  background-color: #30cdcc;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 5px;
  left: -26px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url(../images/check-box-custom-icons.svg);
  background-size: 19px;
  background-position: 0px;
  width: 18px;
  height: 18px;
  left: -30px;
  top: 1px;
}

.site-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.indeterminate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.payment-processing {
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 70px;
}

.modal-sm {
  max-width: 374px;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h1,
h2,
h3 {
  font-weight: bold;
  color: var(--header-text);
  margin: 0;
}

.modal-header,
.modal-footer {
  border: none;
}

.text-danger {
  color: var(--danger) !important;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent !important;
  opacity: 1;
  height: 35px;
  width: 35px;
  padding: 0 !important;
  border-radius: 50%;
  margin: 0 !important;
  right: 15px;
  top: 15px;
}

.close span {
  position: relative;
  color: #818186;
  text-shadow: none;
  top: -1px;
  font-size: 2rem;
  font-weight: 400;
}

.card {
  border: none;
}

.card-headers,
.card-header {
  background-color: #fff;
  border: none;
}

.card-header h2 {
  font-size: 18px;
  line-height: 28.13px;
  color: #121213;
}

.card-headers h2 {
  font-size: 18px;
  line-height: 28.13px;
  color: #121213;
}

label {
  color: #121213;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
}

.floating-input:disabled {
  /* background-color: #DFDCDF !important; */
  background-color: rgba(223, 220, 223, 0.35) !important;
  pointer-events: none;
}

.error input.floating-input,
.error input.form-control,
.error textarea.floating-input {
  border-color: var(--danger) !important;
  outline: solid 1px var(--danger);
}

.success input.floating-input,
.success input.form-control,
.success textarea.floating-input {
  border-color: #28a745 !important;
  outline: solid 1px #28a745;
}

::placeholder {
  opacity: 1;
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

:-ms-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

::-ms-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

::-webkit-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

::-moz-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/*---*/

.react-datepicker-wrapper .floating-input::placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-datepicker-wrapper .floating-input:placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-datepicker-wrapper .floating-input:-ms-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-datepicker-wrapper .floating-input::-ms-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-datepicker-wrapper .floating-input::-webkit-input-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.react-datepicker-wrapper .floating-input::-moz-placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/****  floating-Lable style start ****/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #817f80;
  font-weight: bold;
  background-color: #fff;
  -webkit-text-fill-color: #817f80;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.floating-label {
  position: relative;
  margin-bottom: 20px;
}

.form-group>.floating-label {
  margin-bottom: 5px;
}

.floating-label input,
.floating-label select,
.floating-label textarea {
  font-size: 14px;
  line-height: 16.41px;
  padding: 16px 17px;
  border: 0.5px solid rgba(176, 176, 176, 0.5);
  /* color: #817f80; */
  color: #121213;
  /* font-weight: bold !important; */
  border-radius: 6px;
  cursor: text;
  width: 100%;
  background: #fff !important;
  height: 44px;
}

.floating-label textarea {
  height: unset !important;
}

.floating-label select {
  cursor: default;
  height: 52px;
  background: #fff !important;
  background-color: #fff !important;
}

.floating-label input:focus,
.floating-label select:focus,
.floating-label textarea:focus {
  outline: 0;
  border-color: rgb(251 154 0 / 50%);
}

.floating-label label {
  font-size: 14px;
  color: #817f80;
  line-height: 16.41px;
  background-color: #fff;
  padding: 0px;
  pointer-events: none;
  cursor: text;
  font-weight: bold !important;
  position: absolute;
  left: 18px;
  top: 14px;
  width: calc(100% - 34px);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-label .floating-input:disabled+label {
  background-color: #f4f3f4;
}

.floating-label label span {
  font-weight: normal;
}

.floating-select {
  min-height: 50px !important;
}

.floating-input:focus~label,
.floating-input:not(:placeholder-shown)~label,
.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  top: -8px;
  left: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fb9a00;
  padding: 1px 6px;
  width: auto;
}

.floating-input:not(:placeholder-shown)~label,
.floating-select:not([value=""]):valid~label {
  color: #817f80;
}

.floating-select:focus~label,
.floating-select:not([value=""]):valid~label {
  cursor: pointer;
}

.floating-label ::-webkit-input-placeholder,
.floating-label ::placeholder {
  opacity: 0 !important;
  transition: inherit;
}

.floating-label input:focus::-webkit-input-placeholder,
.floating-label input:focus::placeholder,
.floating-label select:focus::-webkit-input-placeholder,
.floating-label select:focus::placeholder,
.floating-label textarea:focus::-webkit-input-placeholder,
.floating-label textarea:focus::placeholder {
  opacity: 1 !important;
}

/*---CUSTOM -DROPDOWN-PLACEHOLDER  ----*/
.floating-input:focus~.css-1wa3eu0-placeholder,
.floating-input:not(:placeholder-shown)~.css-1wa3eu0-placeholder,
.floating-select:focus~.css-1wa3eu0-placeholder,
.floating-select:not([value=""]):valid~.css-1wa3eu0-placeholder {
  top: -8px;
  left: 12px;
  font-size: 14px;
  color: #fb9a00;
  padding: 1px 6px;
  width: auto;
}

.floating-input:not(:placeholder-shown)~.css-1wa3eu0-placeholder,
.floating-select:not([value=""]):valid~.css-1wa3eu0-placeholder {
  color: #817f80;
}

.floating-select:focus~.css-1wa3eu0-placeholder,
.floating-select:not([value=""]):valid~.css-1wa3eu0-placeholder {
  cursor: pointer;
}

.select-floating-label.css-1wa3eu0-placeholder {
  padding: 0 7px;
  margin: 0;
  left: 10px;
  top: -4px;
  background-color: #fff;
  font-weight: bold;
  max-width: calc(100% + 30px);
}

.select-floating-label.css-1wa3eu0-placeholder+div {
  margin-left: 0;
}

.css-1wa3eu0-placeholder {
  font-weight: bold;
}

.floating-label .react-datepicker-wrapper ::-webkit-input-placeholder,
.floating-label .react-datepicker-wrapper ::placeholder {
  opacity: 1 !important;
  transition: inherit;
}

.pointer-cursor {
  cursor: pointer !important;
}

.table-vertical-scroll {
  max-height: 300px;
  overflow-y: auto;
  min-height: 225px;
}

button.rbc-active {
  background-color: #30cdcc !important;
  color: #fff;
}

@media screen and (max-width: 991px) {
  .middle-section .container {
    padding: 0;
  }

  .appointment-sec .smart-table tbody tr td,
  .appointment-sec .smart-table.table-lg tbody tr td {
    padding: 8px 20px;
  }

  .no-gutters-mbl {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters-mbl>.col,
  .no-gutters-mbl>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .main-card .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }

  .modal-header {
    padding: 1rem 1rem 1rem;
  }

  .modal-body {
    padding: 1rem 1rem 0;
  }

  .modal-footer {
    padding: 0 1rem 1rem;
  }
}

/*----------------------------------------*/

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.form-control {
  border-radius: 0;
  font-size: 14px;
  border-color: rgb(177 177 177 / 50%);
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgb(251 154 0 / 50%);
  outline: 0;
  box-shadow: none;
}

.error {
  color: var(--danger);
}

.btn {
  border-radius: 0;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 18px;
  border-width: 2px;
  border-radius: 6px;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
}

.btn.btn-sm {
  padding: 9px 11px;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.page-link:focus {
  box-shadow: none !important;
}

.btn-primary {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:focus,
.btn-primary:active {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgb(255 152 0 / 56%);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 152 0 / 56%);
}

.btn-secondary {
  background-color: transparent;
  color: var(--primary);
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.disabled,
.btn-secondary:disabled {
  /* background-color: var(--primary); */
  background-color: transparent !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.btn-dark {
  background-color: var(--text-colors-light-grey-500, #E9E9EC);
  color: #818186;
  border: 1px solid var(--text-colors-light-grey-500, #E9E9EC);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.disabled,
.btn-dark:disabled {
  background-color: var(--text-colors-light-grey-500, #E9E9EC) !important;
  border: 1px solid #979797 !important;
  color: #979797 !important;
}

.btn-danger {
  background-color: transparent;
  color: #DB0200;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: transparent;
  border: 1px solid var(--danger);
  color: var(--danger);
}

.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: #00cccc;
}

.custom-dropdown .btn {
  background-color: transparent;
  color: var(--info);
  border-color: #C2C2C5;
  border-radius: 6px;
}

.custom-dropdown .dropdown-menu {
  font-size: 14px;
  min-width: 105px;
}

.custom-dropdown .dropdown-menu .dropdown-item {
  padding: 10px 15px;
  display: flex;
  column-gap: 8px;
  color: #212529 !important;
  align-items: center;
  text-decoration: none !important;
}

.custom-dropdown .dropdown-menu .dropdown-item img {
  width: 18px;
  height: 18px;
}

.breadcrumb {
  background-color: transparent;
  font-size: 14px;
  padding: 0;
}

.table {
  color: var(--form-text);
}

.table thead th {
  color: var(--header-text);
  border-top: none;
  border-bottom-width: 1px;
  vertical-align: middle;
}

.table td,
.table th {
  border-top: none;
  vertical-align: top;
}

.customize-stage-table td,
.customize-stage-table th {
  vertical-align: middle !important;
}

.table.table-lg td,
.table.table-lg th {
  /* padding: 22px; */
  padding: 21px;
  word-break: break-all;
  max-width: 302px;
}

.site-header .site-header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  height: 70px;
  transition: all ease 0.3s;
}

.site-header .navbar-toggler {
  position: absolute;
  top: 18px;
  left: 9px;
  display: none;
}

.site-header .navbar-toggler img {
  height: 21px;
}

.site-header .user-login_dropdown .avatar-img {
  width: auto;
  height: 19px;
  margin-right: 10px;
}

.site-header .user-login_dropdown .dropdown-toggle {
  padding: 12px 15px;
}

.site-header .user-login_dropdown .dropdown-toggle span {
  display: inline-block;
  color: #4d5d5d;
}

.site-header .user-login_dropdown .dropdown-menu {
  border-radius: 0;
}

.site-header .user-login_dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 9px 20px;
}

.site-header .navbar-brand {
  font-size: 35px;
  font-weight: bold;
  color: #000;
  transition: all ease 0.3s;
}

.site-header .navbar-brand span {
  color: #30cdcc;
}

.site-navbar {
  padding: 30px 0 0;
  font-size: 16px;
  border-bottom: solid 1px #d0d0d0;
  transition: all ease 0.3s;
  opacity: 1;
}

.site-navbar .navbar-nav {
  margin-bottom: -2px;
}

.site-navbar .navbar-nav .nav-item .nav-link {
  font-weight: bold;
  color: #fb9a00;
  line-height: 1.5;
  padding: 16px 25px;
  border-width: 5px 1px 1px 1px;
  border-color: transparent;
  border-style: solid;
  border-radius: 6px 6px 0 0;
  margin-right: 4px;
}

.site-navbar .navbar-nav .nav-item.active .nav-link {
  border-color: var(--selected) #d0d0d0 #fff #d0d0d0;
  position: relative;
  z-index: 1;
  color: #5c5c5c;
}

.site-navbar .navbar-nav .nav-item .nav-link .count {
  display: none;
}

.fixed--header {
  padding-top: 189px;
}

.fixed-email-header {
  padding-top: 200px;
}

.fixed--header .site-navbar {
  height: 92px;
}

.fixed--header #main-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
  background-color: #fff;
}

.fixed--header.fixed--elements .site-navbar {
  height: 0;
  opacity: 0;
  padding: 0;
  border-color: transparent;
  transition: all ease 0.3s;
  pointer-events: none;
}

.fixed--header .contact--header {
  transition: all ease 0.3s;
  position: fixed;
  top: 80px;
  z-index: 2;
  width: 100%;
  align-items: center;
  min-height: 109px;
  display: flex;
}

.fixed--header.fixed--elements .contact--header {
  top: 67px;
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.07);
}

.fixed--header.fixed--elements .site-header.site-header_wrap {
  height: 59px;
}

.fixed--header.fixed--elements .site-header .navbar-brand {
  font-size: 18px;
}

.plan-expire-alert {
  position: relative;
  text-align: center;
  top: -16px;
  /*position: fixed;
    width: 100%;
    top:244px ;
    z-index: 99;*/
}

.fixed--header.fixed--elements .plan-expire-alert {
  /*top: 179px;*/
}

.plan-expire-alert .alert.plan-alert {
  position: absolute;
  width: 100%;
  max-width: 810px;
  left: 50%;
  font-size: 12px;
  font-weight: 600;
  line-height: 6px;
  right: 0;
  transform: translate(-50%);
  color: #6b6b6b;
  background-color: rgba(251, 154, 0, 0.2);
  border: 0;
  border-color: #30cdcc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 17px;
  top: 4px;
}

.plan-expire-alert .alert.plan-alert .alert-btns {
  display: flex;
  align-items: center;
}

.plan-expire-alert .alert.plan-alert .alert-btns .close-link {
  background-color: #666;
  border-radius: 50%;
  padding: 3px;
}

.plan-expire-alert .alert.plan-alert .text-link {
  margin-right: 30px;
  line-height: 19px;
}

.site-body {
  background-color: rgba(242, 242, 242, 0.5);
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media only screen and (min-width: 769px) {
  .site-body {
    margin-left: 72px;
  }

  .fixed--header .contact--header {
    width: calc(100% - 72px);
  }
}

.page-title {
  background-color: #fff;
  padding: 20px 0;
  /* padding: 20px 70px; */
}

.page-title .title--col {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.page-title .title--col .title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  color: #121212;
  align-items: center;
}

.page-title .title--col .title .title--text {
  max-width: 600px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.page-title .title--col .breadcrumb {
  margin: 0;
  padding: 0 0 8px;
}

.page-title .custom-dropdown .dropdown-menu {
  min-width: 70px;
}

.middle-section {
  padding: 20px 0;
}

.contacts--table tbody td:first-child {
  width: 60px;
  align-self: center;
}

.smart-table tbody td:first-child .form-check {
  top: -10px;
}

.smart-table tbody td:first-child .form-check .form-check-input {
  height: 15px;
  width: 15px;
}

.contact--id_mbl {
  display: none;
}

.site-footer {
  background-color: #666666;
  color: #c0c0c0;
  padding: 19px 0 16px;
  margin-top: auto;
}

.site-footer a {
  color: #c0c0c0;
}

.footer-row {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-row li {
  padding: 0 15px;
}

.footer-row li:last-child {
  padding-left: 0;
}

.footer-row li:last-child a {
  border-left: solid 1px #999;
  padding-left: 15px;
}

.footer-col_right {
  text-align: right;
}

.footer-col_left {
  text-align: left;
}

.copyright {
  margin-top: 7px;
}

.social-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social-btns a {
  display: inline-block;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5;
  margin-left: 10px;
}

.social-btns a img {
  width: auto;
  max-width: 16px;
  height: 15px;
}

.footer-col_left a img {
  width: 14px;
  opacity: 0.59;
}

.footer--nav {
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.footer--nav a {
  margin: 0 10px;
}

.footer--nav a:first-child {
  margin-left: 0;
}

.login_signup .site-header .site-header_wrap {
  justify-content: center;
}

.login_signup .site-body {
  align-items: center;
  display: flex;
}

.login_signup .site-body .middle-section {
  flex: 0 0 100%;
}

.login_signup .site-body .middle-section .card .card-header .card-headers {
  background-color: #fff;
  border-bottom: none;
  text-align: center;
  padding: 30px 30px 10px;
}

.login_signup .site-body .middle-section .card .card-header .card-headers h5 {
  margin: 0;
  font-size: 24px;
  color: #817f80;
}

.login_signup .site-body {
  margin-bottom: 0;
}

.login_signup .container {
  padding: 0 15px;
}

.login_signup .middle-section .card-body {
  padding-left: 30px;
  padding-right: 30px;
}

.btn--card-collapse {
  display: none;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  padding: 20px;
}

.biz-snapshot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: auto;
}

.biz-snapshot .biz-snapshot_inner {
  display: flex;
  padding-bottom: 10px;
  flex: 0 0 100%;
  justify-content: space-between;
}

.biz-snapshot .biz-snapshot_inner .biz-snapshot--box {
  border: solid 1px #DAE0E0;
  border-radius: 12px;
  padding: 19.3px 24px;
  display: flex;
  flex-direction: column;
  flex: 0 0 212px;
  position: relative;
  background-color: #fff;
}

.snapshot--box_divider {
  display: flex;
  align-items: center;
  padding: 0px;
}

.biz-snapshot .biz-snapshot_inner .biz-snapshot--box:last-child {
  margin-right: 0;
}

.biz-snapshot .biz-snapshot_inner .biz-snapshot--box .price {
  font-size: 28px;
  font-weight: 500;
  color: #121213;
  margin-top: 8px;
  margin-bottom: 0px;
}

.biz-snapshot .biz-snapshot_inner .biz-snapshot--box .biz--status {
  color: var(--text-secondary, #818186);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.contact--no img {
  display: none;
}

.field-text {
  color: #817f80;
  font-weight: bold;
  margin-bottom: 8px;
}

.field-text small {
  font-size: 14px;
}

.table.contacts--table {
  color: #6b6b6b;
}

.notes-tasks_nav {
  display: flex;
  background-color: #fff;
}

.nav-tabs.notes-tasks_nav .nav-link {
  flex: 0 0 50%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-top: 5px solid transparent;
  color: var(--primary);
}

.nav-tabs.notes-tasks_nav .nav-item.show .nav-link,
.nav-tabs.notes-tasks_nav .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-top-color: #30cdcc;
  color: var(--dark);
}

.notes--table td:first-child {
  white-space: nowrap;
  vertical-align: top;
}

.stage--preview-container {
  border: solid 1px #eee;
}

.drag-note {
  margin-top: 10px;
  text-align: center;
}

.leads-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 2220px;
}

.leads-container.quotes-section {
  max-height: 100%;
}

.leads-container.stage--preview-container {
  height: auto;
}

.leads-container .leads-container_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.no-lead.leads-container {
  height: auto;
}

.leads-container .leads-container_row .leads-col {
  flex: 0 0 20%;
  max-width: 214px;
  min-width: 214px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.leads-container .leads-container_row.lead-stage-adjust .leads-col {
  flex: 1 0 20%;
  max-width: 100%;
}

.leads-container .leads-container_row.lead-stage-adjust .leads-col:last-child .leads-col_body {
  margin-right: 0;
}

.no-lead.leads-container .leads-container_row .leads-col {
  height: auto;
}

.leads-container .leads-container_row .leads-col .leads-col_body {
  flex-grow: 1;
  /*margin-top: 19px;*/
  padding-top: 15px;
  height: 100%;
  /* background-color: #efefef; */
  margin-right: 14px;
}

.main-card .leads-container .leads-container_row .leads-col {
  flex: 0 0 20%;
  max-width: 226px;
  min-width: 226px;
}

.main-card .leads-container.stage--preview-container .leads-container_row .leads-col {
  height: auto;
}

.leads-container .leads-container_row .leads-col .leads-col_header {
  display: flex;
  background-color: #fff;
  padding: 24px 0 24px 10px;
  position: sticky;
  top: 0;
}

.leads-container .leads-container_row .leads-col:first-child .leads-col_header {
  border-radius: 5px 0 0 5px;
}

.leads-container .leads-container_row .leads-col:last-child .leads-col_header {
  border-radius: 0 5px 5px 0;
}

.leads-container.stage--preview-container .leads-container_row .leads-col .leads-col_header {
  padding: 20px 6px 15px 0;
}

.leads-container .leads-container_row .leads-col .leads-col_header .header--web {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 31px;
}

.leads-container .leads-container_row .leads-col .leads-col_header .header--web h4 {
  flex: 1 0 auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  color: #4a4a4a;
}

.leads-container .leads-container_row .leads-col:last-child .leads-col_header .header--web img {
  display: none;
}

.leads-container .leads-container_row .leads-col .leads-col_body .leads-col_body--wrap {
  padding: 1px 6px 0px 6px;
}

.dragable--card {
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); */
  margin-bottom: 15px;
}

.dragable--card * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropable-areaa {
  background-color: #efefef;
}

.dropable-areaa .leads-col_body {}

.dragable--card.active {
  background-color: #cbf2f2 !important;
  border: solid 2px #30cdcc;
  box-shadow: none;
}

.dragable--card.completed {
  border-top: solid 4px #30cdcc;
}

.dragable--card.lost {
  border-top: solid 4px #9a1710;
}

.dragable--card.expired {
  border-top: solid 4px #817f80;
}

.dragable--card .title {
  font-size: 14px;
  font-weight: bold;
  color: #4a90e2;
  word-break: break-all;
  margin-bottom: 10px;
  line-height: 18px;
}

.dragable--card .info {
  font-size: 12px;
  cursor: grab;
  color: #121213;
  word-break: break-all;
  font-weight: 500;
}

.dragable--card .info p {
  color: #121213;
}

.dragable--card .info span {
  display: block;
  font-weight: bold;
  margin-right: 10px;
}

.dragable--card .total-tasksnotes {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.dragable--card .total-tasksnotes .totalnotes,
.dragable--card .total-tasksnotes .totaltasks {
  height: 24px;
  font-size: 10px;
  width: 24px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.dragable--card .total-tasksnotes .totaltasks {
  background-color: var(--danger);
  color: #fff;
}

.dragable--card .total-tasksnotes .star-confirmed {
  height: 24px;
  font-size: 10px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.moveupdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moveupdown .btn {
  padding: 10px;
}

.moveupdown .btn svg {
  fill: #9b9b9b;
}

.moveupdown .btn.active svg {
  fill: #fb9a00;
}

.new-lead-timeline {
  display: flex;
  /* overflow-x: auto; */
}

.new-lead-timeline .timeline_row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.new-lead-timeline .timeline_row .timeline-cols {
  flex: 1 0 auto;
  justify-content: flex-start;
  display: inline-flex;
  padding-left: 43px;
  align-items: center;
  position: relative;
  height: 44px;
  border: solid 1px #e1e1e1;
}

.new-lead-timeline .timeline_row .timeline-cols:hover {
  background-color: #d8d8d8;
  border-color: #35cecd;
}

.new-lead-timeline .timeline_row .timeline-cols:hover span:before {
  border-left: 16px solid #d8d8d8;
}

.new-lead-timeline .timeline_row .timeline-cols h5 {
  text-align: center;
  margin: 0;
  flex: 1 0 auto;
  font-size: 18px;
  padding-right: 6px;
}

.timeline_row .timeline-cols:last-child h5 {
  margin-right: 0;
}

.new-lead-timeline .timeline_row .timeline-cols span {
  display: inline-block;
  position: absolute;
  left: 100%;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-left: 16px solid #d8d8d8;
  border-bottom: 22px solid transparent;
  z-index: 1;
}

.new-lead-timeline .timeline_row .timeline-cols em {
  font-style: normal;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  justify-content: center;
  color: #818186;
}

.new-lead-timeline .timeline_row .timeline-cols.active em {
  font-weight: bold;
  color: #121213;
}

.new-lead-timeline .timeline_row .timeline-cols.active i {
  font-weight: bold;
  display: none !important;
}

.new-lead-timeline .timeline_row .timeline-cols span:before {
  content: "";
  position: absolute;
  top: -22px;
  left: -17px;
  display: inline-block;
  width: 0;
  height: 0;
  z-index: -1;
  border-top: 22px solid transparent;
  border-left: 16px solid #fff;
  border-bottom: 22px solid transparent;
}

.new-lead-timeline .timeline_row .timeline-cols.active {
  background-color: #cbf2f2;
  border-color: #35cecd;
  flex: 1 0 auto;
  background-color: #D6F5F5;
}

.new-lead-timeline .timeline_row .timeline-cols.active h5 {
  /*-- padding: 0 20px 0 26px;-*/
}

.new-lead-timeline .timeline_row .timeline-cols:first-child {
  padding-left: 10px;
}

.new-lead-timeline .timeline_row .timeline-cols.active span,
.new-lead-timeline .timeline_row .timeline-cols:hover span {
  border-left: 16px solid #30cdcc;
}

.new-lead-timeline .timeline_row .timeline-cols.active span:before {
  border-left: 16px solid #D6F5F5;
}

.new-lead-timeline .timeline_row .timeline-cols.completed {
  border: solid 1px #30cdcc;
  cursor: grab;
  background: #D6F5F5;
  stroke-width: 1px;
}

.new-lead-timeline .timeline_row .timeline-cols.completed span {
  border-left: 16px solid #30cdcc;
}

.new-lead-timeline .timeline_row .timeline-cols.completed em,
.new-lead-timeline .timeline_row .timeline-cols.completed i {
  color: #000;
  font-weight: bold;
}

.timeline_row .timeline-cols:last-child {
  flex: 1 0 auto;
  max-width: 100%;
}

.timeline_row .timeline-cols:last-child span {
  display: none !important;
}

.single-label {
  font-weight: bold;
}

.font-small {
  font-size: 20px;
  margin-left: 10px;
  color: var(--text-colors-grey-500, #818186);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quote-details {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.quote-details .quote-id {
  font-size: 14px;
  font-weight: bold;
  color: #4a90e2;
  margin-bottom: 5px;
}

.quote-details .quote-time {
  font-size: 12px;
  color: #9b9b9b;
}

.leader--source {
  text-align: right;
}

.leader--source a {
  font-size: 14px;
  font-weight: bold;
  color: #4a90e2;
  margin-left: 5px;
}

.notify-completed,
.notify-lost {
  padding: 3px 10px;
  text-align: center;
  font-weight: bold;
  color: #4a4a4a;
}

.notify-completed {
  background-color: #cbf2f2;
}

.notify-lost {
  background-color: #efbebe;
}

.closed-container_header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 13px;
}

.closed-container_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.closed-container_row .dragable--card {
  border-radius: 4px;
  flex: 0 0 19%;
  margin-right: 11px;
}

.empty-leads {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-size: 16px;
  color: #4a4a4a;
}

.calendar--table th.no-wrap {
  white-space: nowrap;
}

.calendar--table th.no-wrap span {
  font-weight: normal;
}

.calendar--table .calendar--datetime span {
  display: block;
}

.input-group.floating-label .floating-input {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.fixed--elements .error-class-container {
  top: 172px;
}

.errorCls {
  color: var(--danger);
  font-size: 14px;
}

.errCommonCls {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 4px 20px;
  background: #efbebe;
}

.errCommonCls .error-close {
  position: absolute;
  right: -5px;
}

.errCommonCls img {
  margin-right: 12px;
}

.errCommonCls::after {
  display: none;
}

.service--message {
  flex-direction: column;
}

.service--message img {
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.swal2-header {
  padding: 0 !important;
}

.modal-content {
  /* max-height: 90vh; */
  overflow: auto;
}

.modal-header {
  position: sticky;
  top: 0;
}

.modal-body {
  overflow-y: scroll;
}

.modal-footer {
  position: sticky;
  bottom: 0;
}

.modal-content .modal-header .sr-only {
  display: none;
}

.task--status {
  min-width: 132px;
}

.css-yk16xz-control {
  border-color: #C2C2C5 !important;
  height: 44px !important;
  color: #817f80 !important;
}

.css-yk16xz-control>div,
.css-1pahdxg-control>div {
  padding-right: 0 !important;
}

.css-g1d714-ValueContainer {
  padding: 0 8px !important;
}

.css-1pahdxg-control {
  border-color: rgb(251 154 0 / 50%) !important;
  border-radius: 6px !important;
  height: 44px !important;
  box-shadow: none !important;
}

.card-header .css-1pahdxg-control {
  height: 47px !important;
}

.card-headers .css-1pahdxg-control {
  height: 47px !important;
}

.card-header .css-yk16xz-control {
  height: 47px !important;
}

.card-headers .css-yk16xz-control {
  height: 47px !important;
}

.css-1uccc91-singleValue {
  color: #121213 !important;
  font-weight: 500;
}

.site-pagination .pagination .page-item .page-link {
  color: #979797;
}

.site-pagination .pagination .page-item.active .page-link {
  color: #fff;
  background-color: #30cdcc;
  border-color: #30cdcc;
  z-index: auto !important;
}

.site-pagination .pagination .page-item.disabled .page-link {
  color: #ccc;
}

.no--contacts {
  background-color: #fff;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no--contacts h5 {
  margin-bottom: 25px;
  font-size: 16px;
  font-weight: bold;
}

.browe--file {
  background-color: #f2f2f2;
  padding: 20px 20px 15px;
  margin-bottom: 27px;
}

.file-added {
  font-weight: bold;
  margin-bottom: 11px;
}

.browe--file .btn-secondary {
  color: var(--primary);
  margin-bottom: 11px;
}

.p-small {
  font-size: 12px;
}

.no--contacts--note {
  background-color: #fff;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no--contacts--note h5 {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: normal;
  color: rgba(107, 107, 107, 0.75) !important;
}

.table--sorting {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  white-space: nowrap;
}

.sorting-icn {
  display: flex;
  flex-direction: column;
}

.sorting-icn a {
  padding: 2px;
  display: flex;
}

.sorting-icn a.up {
  transform: rotateX(180deg);
}

.sorting-icn a svg path {
  color: #999;
  opacity: 0.2;
}

.sorting-icn a.active svg path {
  fill: #15b6b5;
  opacity: 1;
}

.logo_wrapp {
  display: flex;
}

.company--logo .logo-label {
  font-weight: bold !important;
  margin-bottom: 4px;
  color: #817F80;
  font-size: 16px;
  line-height: normal;
}

.company--logo .logo-label span {
  font-weight: normal;
}

.company--logo p {
  color: #817F80;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin: 15px 0;
}

.company--logo:first-child p {
  margin: 11px 0 5px 0;
}

.upload-components {
  height: 94px;
  flex-shrink: 0;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
}

.logo-input,
.headerImage-input {
  display: none;
}

.upload-logo {
  width: 55px;
  height: 49px;
  flex-shrink: 0;
  border: 1px solid #979797;
  background-color: #D8D8D8;
}

.upload-label {
  color: #4A90E2;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: normal;
  margin-left: 4px;
}

.upload-input {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Email notification */
.Email_notification_business_info p {
  margin-bottom: 0;
}

.Email_notification_business_info_p {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 28px;
}

.Email_notification_business_profile_MainDiv {
  background-color: #EBF9F9;
}

.Email_notification_details p {
  color: #2D2D2D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.Email_notification_business_profile h2 {
  color: #136564;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  height: 50px;
  margin-top: 22px;
  margin-bottom: 22px;
  text-wrap: pretty;
}

.Email_notification_business_profile p {
  color: #2D2D2D;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

/* .headerImage-input {} */

.logo_imgg {
  flex: 0 0 70px;
  padding-top: 10px;
}

.logo_upload {
  padding-left: 12px;
}

.logo_upload-file-instruction {
  font-size: 12px;
}

.logo_upload .logo_upload-file {
  display: flex;
  align-items: center;
}

.logo_upload .logo_upload-file .custom-file {
  max-width: 140px;
}

.delete_file {
  color: #98100f;
  font-weight: bold;
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
  position: absolute;
  right: 10%;
}

.delete_file img {
  margin-right: 4px;
}

.logo_upload .logo_upload-file .custom-file label {
  color: #4a90e2;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding-left: 0;
  width: max-content;
  background-color: transparent !important;
}

.logo_upload .logo_upload-file .custom-file label:after {
  display: none;
}

.outerLocGetCls {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  line-height: 1.3;
  z-index: 1;
}

.outerLocGetCls .locGetCls {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: inline-flex;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  padding: 10px 0;
  width: 100%;
  z-index: 2;
}

.outerLocGetCls .locGetCls .innerLocGetCls {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 11px 20px;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.outerLocGetCls .locGetCls .innerLocGetCls:last-child {
  border-bottom: none;
}

.outerLocGetCls .locGetCls .innerLocGetCls:hover {
  background-color: #cbf2f2;
}

.error--list {
  margin: 0;
  list-style: none;
  padding-left: 10px;
}

.table-action {
  max-width: 100px;
}

.table-action .d-flex {
  justify-content: flex-end;
  align-items: center;
}

.task-view-filter {
  min-width: 120px;
}

.task-view-filters {
  min-width: 182px;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.swal2-icon.swal2-success {
  border-color: #30cdcc !important;
  color: #30cdcc !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #30cdcc !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid #30cdcc !important;
}

.react-datepicker {
  border-radius: 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--primary) !important;
  border-radius: 0;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary) !important;
  border-radius: 0;
}

.react-datepicker__day-names {
  background-color: #dddddd;
}

.react-datepicker__header {
  border: 0 !important;
  background-color: transparent !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #fff;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-bottom: 5px;
}

.react-datepicker__navigation {
  overflow: visible;
  border-color: transparent !important;
  top: 12px !important;
}

.react-datepicker__navigation:focus {
  outline: 0 !important;
}

.react-datepicker__navigation--previous:after,
.react-datepicker__navigation--next:after {
  position: absolute;
  content: "";
  background-size: 8px auto;
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  top: -11px;
}

.react-datepicker__navigation--previous:after {
  background-image: url(../images/arrow-grey-left.svg);
  left: -10px;
}

.react-datepicker__navigation--next:after {
  background-image: url(../images/arrow-grey-right.svg);
  right: -10px;
}

.mycustom-alert {
  border-radius: 0 !important;
}

.mycustom-alert .swal2-header .swal2-title {
  width: 100%;
  font-size: 18px !important;
  line-height: 28px !important;
  font-weight: 700 !important;
  margin-bottom: 22px !important;
  color: #121213;
}

.mycustom-alert .swal2-content {
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  text-align: left !important;
  color: #121213;
}

.mycustom-alert .swal2-actions {
  padding: 0 !important;
  justify-content: flex-end !important;
}

.mycustom-alert .swal2-cancel,
.mycustom-alert .swal2-confirm {
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border-radius: 6px;
  border: 1px solid transparent !important;
  line-height: 1.5 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.mycustom-alert .swal2-cancel:hover {
  color: #979797 !important;
  background-image: none !important;
}

.mycustom-alert .swal2-confirm:hover {
  background-image: none !important;
  color: var(--danger) !important;
}

.mycustom-alert .swal2-cancel {
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 11px 26px !important;
  border-width: 2px !important;
  background-color: #E9E9EC !important;
  color: #818186 !important;
  border-color: #E9E9EC !important;
  border-radius: 6px;
}

.mycustom-alert .swal2-confirm {
  white-space: nowrap !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 11px 26px !important;
  border-width: 2px !important;
  background-color: transparent !important;
  color: #DB0200 !important;
  border: 1px solid #DB0200 !important;
  border-radius: 6px;
}

.mycustom-alert .swal2-close {
  display: flex !important;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  background-color: transparent !important;
  opacity: 1 !important;
  height: 35px !important;
  width: 35px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  font-size: 2rem;
  font-weight: 100;
  font-family: inherit;
  color: #818186;
}

.mycustom-alert .swal2-close:hover {
  color: #818186 !important;
  opacity: 0.7 !important;
}

.mycustom-alert .swal2-close:focus,
.mycustom-alert .swal2-cancel:focus,
.mycustom-alert .swal2-confirm:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.error-unable-to-import {
  align-items: flex-start;
  padding: 10px 20px;
  line-height: 23px;
  justify-content: flex-start;
}

.error-unable-to-import img {
  margin-top: 2px;
}

.customReactSelectMenu {
  position: absolute;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  max-height: 220px;
  overflow-y: auto;
}

.react-datepicker-wrapper {
  display: block !important;
}

.main-card .custom-control {
  z-index: auto;
}

.cstinputusd {
  position: relative;
  margin: 0 15px;
}

.cstinputusd input {
  max-width: 140px;
}

.cstinputusdg-msg {
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
}

.custom-control {
  z-index: auto !important;
}

.view-closed-leads {
  background-color: #f8f8f8;
  padding: 17px 20px 4px 0;
}

.cstselectmin-w {
  min-width: 136px;
}

.input-calendar-icon {
  position: absolute;
  top: 12px;
  right: 9px;
  opacity: 0.9;
}

.input-google-calendar-icon {
  position: absolute;
  top: 30px;
  right: 9px;
  opacity: 0.9;
}

.input-google-calendar-icon-end {
  position: absolute;
  top: 5px;
  right: 9px;
  opacity: 0.9;
}

.stages--table .stage--notes {
  max-width: 320px;
}

.card-header_content h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #4a4a4a;
}

.autocomplete-dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
}

.suggestion-item,
.suggestion-item--active {
  padding: 5px 10px;
}

.autocomplete-dropdown-container .suggestion-item[role="option"] {
  border-left: solid 1px #bcbcbc;
  border-right: solid 1px #bcbcbc;
}

.autocomplete-dropdown-container .suggestion-item[role="option"]:first-child {
  border-top: solid 1px #bcbcbc;
}

.autocomplete-dropdown-container .suggestion-item[role="option"]:last-child {
  border-bottom: solid 1px #bcbcbc;
}

.autocomplete-dropdown-container .suggestion-item--active {
  background-color: #00cccc !important;
  color: #333;
  border-left: solid 1px #bcbcbc;
  border-right: solid 1px #bcbcbc;
}

.notbold {
  font-weight: 400;
}

.task--todo {
  /* width: 71px;*/
}

.task--subject {
  max-width: 272px;
}

.dashboard-task-table .task--subject {
  min-width: 250px;
  max-width: 100%;
}

.dashboard-task-table .task--todo {
  min-width: 95px;
}

.css-1fhf3k1-control {
  height: 50px;
  border-radius: 0 !important;
}

.my-custom-time-input .react-datepicker-popper {
  width: 100%;
}

.my-custom-time-input .react-datepicker,
.my-custom-time-input .react-datepicker__time-container {
  width: 100%;
}

.my-custom-time-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100%;
}

.my-custom-time-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  width: 100%;
}

.my-custom-time-input .react-datepicker__header {
  display: none;
}

.my-custom-time-input .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  text-align: left;
  height: 34px !important;
  padding: 8px 10px !important;
}

.my-custom-time-input .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #e5ebff !important;
}

.related-toDo {
  width: 164px;
  min-width: auto;
}

.sticky-table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.rrs__label {
  position: relative;
  border: 1px solid #bcbcbc;
  border-radius: 0;
}

.rrs__button {
  line-height: 48px;
  color: #4d4d4d;
}

.rrs--options-visible .rrs__options {
  border-radius: 0;
  border-color: #bcbcbc;
}

.rrs__label:after {
  content: "";
  position: absolute;
  right: 7px;
  top: 21px;
  background-image: url(/static/media/down-arrow.00639b04.svg);
  background-size: 12px auto;
  height: 13px;
  width: 13px;
  background-repeat: no-repeat;
}

.rrs__option.rrs__option--next-selection {
  background: transparent;
  color: #4d4d4d;
}

.rrs__option {
  color: #4d4d4d;
}

.rrs__option:hover {
  background-color: #00cccc;
  color: #4d4d4d;
}

.task-countt {
  color: #888;
  font-weight: normal;
}

.form-group .form-row .floating-label {
  margin-bottom: 5px;
}

.smart-table tr .close-icn {
  opacity: 1;
  pointer-events: none;
  transition: all ease 0.3s;
}

.smart-table tr:hover .close-icn {
  opacity: 1;
  pointer-events: all;
}

.quote--create-form {
  padding: 10px 5px;
}

.create-qoute--cards .card-header {
  padding: 15px 28px;
}

.create-qoute--cards .card-header h5 {
  font-size: 18px;
  font-weight: 600;
  color: #121213;
  margin-bottom: 0;
}

.create-qoute--cards .card-header small {
  font-size: 16px;
  color: #818186;
  display: block;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 12px;
}

.btn--card-collapse .floating-label label {
  text-align: left;
}

.create-qoute--cards .btn--card-collapse .floating-label input {
  font-weight: 600;
}

.lineitemwidth {
  flex: 0 0 34.1%;
  max-width: 34.1%;
}

.desc-cstmwidth {
  flex: 0 0 44.63%;
  max-width: 44.63%;
}

.line-item--row:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.line-item--row,
.add-fee-row {
  position: relative;
  display: flex;
}

.line-item--row {
  padding: 20px 20px 0;
}

.line-item--row p.m-0 {
  color: #9b9b9b;
}

.line-item--row>.row,
.add-fee-row>.row {
  flex-grow: 1;
}

.line-item-del {
  width: 40px;
  text-align: center;
  padding-top: 15px;
}

.line-item-delete {
  width: 40px;
  text-align: center;
}

.line-item--add,
.add-fee_discount {
  padding: 15px 0;
  border-bottom: solid 1px #bcbcbc;
}

.line-item-btm-border {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: solid 1px #bcbcbc;
}

.total_row {
  padding: 20px 0;
}

.create-quote-buttons {
  padding: 25px 0;
  text-align: center;
}

.create-quote-buttons .btn {
  margin: 0 10px;
}

.create-qoute--cards.create-form .card-header {
  padding: 30px 25px 0;
}

.create-qoute--cards.create-form .card-header .floating-label {
  width: 32.1%;
}

.create-qoute--cards.create-form .card-header .floating-label label {
  font-size: 18px;
}

.create-qoute--cards.create-form .card-header .floating-label .floating-input {}

.title-elems .css-yk16xz-control {
  height: 44px !important;
}

.quote-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.bizzYourname {
  display: flex;
}

.bizzPhoto {
  flex: 0 0 90px;
  max-width: 90px;
}

.bizzPhoto {}

.YourBizzContent {
  flex-grow: 1;
}

.YourBizzContent label.organization-title-name {
  font-size: 16px;
  font-weight: bold;
}

.col--minus {
  position: relative;
}

.col--minus:before {
  content: "";
  position: absolute;
  left: -24px;
  top: 10px;
  height: 2px;
  width: 13px;
  z-index: 1;
  background-color: #121213;
}

.qt-fees .col--minus:before {
  left: -13px;
  top: 8px;
  height: 2px;
  width: 8px;
  z-index: 1;
}

.qt-activity-name {
  min-width: 350px;
}

.titleName {
  width: 270px !important;
}

.qt-fees {
  width: 280px;
}

.fees {
  width: 640px !important;
}

.amt {
  width: 160px !important;
}

.quote-total-row {
  border-top: solid 1px #bcbcbc;
}

.quote-total-row table td {
  background-color: #fff;
  font-size: 16px;
  color: #121213;
}

.qt-subtotal,
.qt-subtotal+.qt-fees {
  border-bottom: solid 1px #bcbcbc;
}

.qt-total,
.qt-total+.qt-fees {
  border-top: solid 1px #bcbcbc !important;
}

.qt-qttotal {
  width: 300px;
  text-align: right;
  padding-right: 30px !important;
}

.totl {
  text-align: start !important;
}

.reviewQuote--ftr {
  border: 1px solid var(--primary-colors-blue-500, #30CDCC);
  background: var(--background-colors-white, #FFF);
  border-radius: 8px;
}

.rvwqt-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rvwqt-btns {
  text-align: right;
  flex: 0 0 auto;
}

.rvwqt-name {
  flex-grow: 1;
}

.rvwqt-deposit {
  max-width: 230px;
}

.rvwqt-btns .btn {
  margin-left: 15px;
}

.rvwqt-name,
.rvwqt-deposit {
  padding: 10px;
  position: relative;
  min-height: 77px;
}

.rvwqt-name:after,
.rvwqt-deposit:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  background-color: #E9E9EC;
  width: 1px;
  height: 40px;
}

.rvwqt-name h4,
.rvwqt-deposit h4,
.rvwqt-final h4 {
  font-size: 20px;
  font-weight: bold;
  color: #121213;
}

.rvwqt-name p,
.rvwqt-deposit p {
  margin-bottom: 0;
  font-size: 14px;
  color: #818186;
}

.rvwqt-signature {
  padding: 10px;
  min-width: 16%;
  text-align: center;
}

.rvwqt-deposit {
  flex: 1 0 auto;
  text-align: center;
}

.rvwqt-download {
  padding: 10px 0px 10px 10px;
  flex-grow: 1;
  text-align: center;
  max-width: 160px;
}

.rvwqt-download a {
  font-weight: bold;
  color: #121213;
  font-size: 14px;
}

.rvwqt-note {
  margin-bottom: 0;
  margin-top: 20px;
  font-size: 14px;
  color: #818186;
}

.rvwqt-final {
  background-color: #f8f8f8;
  padding: 10px 3%;
  text-align: center;
}

.rvwqt-final p {
  font-size: 12px;
  color: #6b6b6b;
  margin: 0;
}

.message-sender-wrap {
  padding-left: 150px;
  position: relative;
}

.email--to,
.email--subject {
  position: relative;
}

.email--to {
  margin-bottom: 20px;
}

.email--subject {}

.email--to>span,
.email--subject>span {
  position: absolute;
  right: 100%;
  top: 14px;
  padding-right: 12px;
}

.emailField {
  display: flex;
  align-items: center;
}

.emailField strong {
  color: #4a4a4a;
}

.emailField .form-group {
  margin-bottom: 0;
  margin-right: 20px;
}

.email--to .emailField .floating-input {
  width: 280px;
}

.email--subject .emailField .floating-input {
  min-width: 425px;
}

.access--code {
  display: flex;
}

.access--code .custom-control-label {
  margin-top: 0 !important;
}

.access--code .form-control {
  width: 67px;
  margin-left: 20px;
}

.notify_Settings {
  align-items: center;
  margin-bottom: 14px;
}

.notify_Settings .custom-checkbox {
  margin-right: 15px;
}

.notify_Settings .form-control {
  width: auto;
}

.notify_subSettings {
  margin-left: 30px;
}

.add-fee-row .col--minus:before {
  left: -18px;
  top: 23px;
}

.add-fee_discount {
  font-weight: bold;
}

.quote-timeline {
  text-align: center;
}

.quote-timeline .quote-timeline--wrap {
  display: inline-flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 560px;
  margin-bottom: 69px;
  margin-top: 26px;
}

.quote-timeline .quote-timeline--wrap li {
  flex: 0 0 20px;
  border-radius: 50%;
  position: relative;
  height: 20px;
  background-color: #fff;
  border: solid 1px #979797;
}

.quote-timeline .quote-timeline--wrap li:after {
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  top: 2px;
  position: absolute;
  border-radius: 50%;
}

.quote-timeline .quote-timeline--wrap li:before {
  content: "";
  height: 1px;
  width: 240px;
  background-color: #9b9b9b;
  position: absolute;
  left: 24px;
  top: 9px;
}

.quote-timeline .quote-timeline--wrap li:last-child:before {
  display: none;
}

.quote-timeline .quote-timeline--wrap li span {
  position: absolute;
  right: -67px;
  top: calc(100% + 5px);
  display: inline-block;
  white-space: nowrap;
  width: 150px;
}

.quote-timeline .quote-timeline--wrap li span em {
  font-style: normal;
  display: block;
  font-weight: bold;
}

.quote-timeline .quote-timeline--wrap li.completed {
  background-color: #d0d0d0;
}

.quote-timeline .quote-timeline--wrap li.current {
  border: solid 2px #fb9a00;
  height: 22px;
  width: 22px;
  flex: 0 0 22px;
}

.quote-timeline .quote-timeline--wrap li.current:after {
  background-color: #30cdcc;
}

.quote-timeline .quote-timeline--wrap li.expired {
  border: solid 2px #817f80;
  height: 22px;
  width: 22px;
  flex: 0 0 22px;
}

.quote-timeline .quote-timeline--wrap li.expired:after {
  background-color: #817f80;
}

.quote-timeline .quote-timeline--wrap li.revision:after {
  background-color: #98100f;
}

.quote-timeline .quote-timeline--wrap li.revision span {
  color: #98100f;
}

/*--------CUSTOMER-VIEW---------------*/
.customer-view--header {
  padding: 30px 0;
  border-top: solid 5px #30cdcc;
}

.customer-view--header h1 {
  font-size: 28px;
}

.customer-view .site-body {
  border: 1px solid #30cdcc;
}

.customer-view-hdr-logo .navbar-brand {
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  line-height: 20px;
  color: #000;
  transition: all ease 0.3s;
  margin-top: 18px;
  margin-right: 0px;
}

.customer-view-hdr-logo .em {
  margin-bottom: 0;
  font-size: 10px;
  color: #817f80 !important;
  font-style: italic;
  /* display: block; */
}

.customer-view-hdr-logo .navbar-brand span {
  color: #30cdcc;
}

.ck-editor__editable {
  min-height: 100px !important;
}

.card-header .input-edit-wrap {
  max-width: 32.222%;
  flex: 0 0 32.222%;
}

.input-edit-wrap {
  display: flex;
  align-items: center;
}

.input-edit-wrap .input-okbtn {
  flex-grow: 0;
  margin-left: 10px;
}

.input-edit-wrap .input-okbtn .btn {
  padding: 12px 16px;
}

.input-edit-btn {
  position: relative;
  margin-bottom: 0 !important;
  flex-grow: 1;
}

.input-edit-btn .form-control {
  font-size: 16px;
  height: 50px;
  color: #817f80;
  font-weight: 600;
  border-radius: 6px;
}

.input-edit-btn .floating-input {
  padding-right: 44px;
}

.input-edit-btn .btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 10px;
  height: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
}

.float-error .errorValidationMessage {
  position: absolute;
  left: 0;
  top: 100%;
  display: inline-block;
}

.modal-body h5 {
  color: #6b6b6b;
  font-size: 18px;
  font-weight: 600;
}

.quote-last-update {
  font-size: 10px;
  color: #818186 !important;
  margin-top: 9px;
  text-align: end;
}

.deposit-reqr>p {
  min-width: 190px;
}

.deposit-reqr .deposit_Usd {
  color: #9b9b9b;
  position: relative;
}

.deposit-reqr .deposit_Usd .quoteDepositErr,
.amountdue-opt .quoteDepositErr {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 2px;
  color: #98100f;
  white-space: nowrap;
}

.deposit-reqr .deposit_Usd input {
  max-width: 100px;
}

.newlabelsetp label {
  font-weight: 600;
}

.newlabelsetp .field-text {
  font-weight: 500;
  color: #9b9b9b;
  font-size: 14px;
}

.disabled-button-state .btn,
.disabled-button-state a {
  pointer-events: none;
}

.react-datepicker-popper {
  z-index: 11;
}

.main-site.unfixed-page-title {
  padding-top: 80px;
}

.main-site.unfixed-page-title .page-title {
  position: static;
  margin-bottom: 20px;
}

.main-site.unfixed-page-title.fixed--elements #main-header {
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.07);
}

.quote-bizzlogo--name {
  display: flex;
  align-items: center;
}

.quote-bizzlogo {
  max-width: 225px;
}

.quote-bizzlogo img {
  max-width: 100%;
  padding-right: 30px;
}

.quote-bizzname {
  font-size: 28px;
  font-weight: bold;
  color: #121213;
}

.bizzamount-price {
  font-size: 24px;
  color: #6b6b6b;
}

.quote-user-name {
  font-size: 18px;
  font-weight: 600;
}

.quote-bizz-details {
  text-align: right;
}

.btn-divider {
  width: 1px;
  height: 40px;
  background-color: #E9E9EC;
}

.merchant-id-class {
  font-size: 16px;
}

.yourid-wrap,
.paymentnotice-wrap {
  background-color: #f9f9f9;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.yourid-wrap h5 {
  font-weight: bold;
  font-size: 16px;
}

.yourid-wrap p {
  font-size: 16px;
  margin-bottom: 0;
}

.yourchangeid {
  font-size: 16px;
  font-weight: bold;
}

.paymentnotice-wrap .noticecol {
  flex: 0 0 50%;
  margin-bottom: 0;
}

.paymentbtn {
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.paymentbtn p {
  margin-top: 10px;
  margin-bottom: 0;
}

.noticecol strong {
  color: #98100f;
}

.payment-modal-header {
  align-items: center;
}

.payment-modal-header .modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #121213;
}

.payment-modal-header .modal-amount {
  background-color: #f7f5f6;
  padding: 14px 16px;
  font-weight: bold;
  font-size: 24px;
  color: #4e4e4e;
}

.bookingResp_table table.table.table-lg td.booking--date .booking-list-date {
  white-space: nowrap;
}

.amount-due-sec {
  background-color: #f7f5f6;
  padding: 10px 20px;
  color: #4e4e4e;
  font-size: 24px;
  margin-right: 35px;
  font-weight: bold;
  white-space: nowrap;
}

.amountdue-opt {
  position: relative;
}

.amountdue-opt .form-inline {
  flex: 0 0 147px;
}

.amountdue-opt .form-inline .form-control {
  width: 115px;
}

.modal-md-lg .modal-dialog {
  max-width: 675px;
}

#welcome-screen {
  color: #2d2d2d;
}

#welcome-screen .modal-content {
  position: relative;
  box-shadow: 6px 6px 15px 0px #6B6B6B80;
}

#welcome-screen .wlcm-svg2,
#welcome-screen .wlcm-svg1 {
  opacity: 0.5;
  position: absolute;
  left: 0;
}

#welcome-screen .wlcm-svg1 {
  top: 0;
}

#welcome-screen .wlcm-svg2 {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

#welcome-screen .modal-body {
  /* padding: 4rem 3.2rem 0; */
  padding: 10px 38px 35px 38px;
  position: relative;
  z-index: 2;
}

#welcome-screen h1 {
  font-size: 36px;
  color: #2d2d2d;
  margin-bottom: 20px;
}

#welcome-screen h1 span {
  color: #30cdcc;
}

#welcome-screen h4 {
  color: rgba(45, 45, 45, 0.70);
  opacity: 70% !important;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
}

#welcome-screen h3 {
  font-size: 20px;
  color: #2D2D2D;
  font-weight: 400;
  margin-bottom: 30px;
}

#welcome-screen h6 {
  margin-bottom: 30px;
  color: #2D2D2D;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

#welcome-screen .welcome-points {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12),
    0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  padding: 49px 23px 21px;
  position: relative;
  height: 100%;
  margin-left: 17px;
  margin-right: 17px;
}

#welcome-screen .welcome-points span {
  height: 46px;
  width: 46px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  background-color: #30cdcc;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 50%;
  top: -24px;
  font-size: 24px;
  margin-left: -23px;
}

#welcome-screen .welcome-points p {
  font-size: 16px;
  line-height: 24px;
}

#SubscriptionPopUp .modal-dialog {
  max-width: 585px;
}

#SubscriptionPopUp .modal-title {
  font-size: 24px;
  color: #817f80;
  font-weight: 600;
}

.subscribe--plans .subsc-collapse-btn {
  font-size: 20px;
  font-weight: 600;
  color: #817f80;
  text-decoration: none !important;
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.subsc-collapse-btn.pb-4 {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

.subscribe--plans .collapsetilte span {
  font-size: 14px;
}

.subscribe--plans .collapsetilte p {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 8px;
}

.subscribe--plans .subsc-collapse-btn .subs-trial-info {
  flex: 0 0 100%;
  font-size: 12px;
  margin-left: 38px;
  margin-top: 7px;
  font-weight: 400;
  font-size: 12px;
  height: 22px;
  opacity: 1;
  overflow: visible;
  transition: all ease 0.3s;
}

.subscribe--plans .subsc-collapse-btn[aria-expanded="true"] .subs-trial-info {
  opacity: 0;
  overflow: hidden;
  height: 0;
}

.subscribe--plans .subsc-collapse-btn img {
  margin-right: 13px;
  transition: all ease 0.3s;
  transform: rotateZ(-90deg);
  position: relative;
  top: -2px;
}

.subscribe--plans .subsc-collapse-btn[aria-expanded="true"] img {
  transform: rotateZ(0);
}

.subscription-duration {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subs-select-price .subs-price {
  font-size: 24px;
  color: #817f80;
  font-weight: 600;
}

.subs-select-price .subs-price span {
  font-size: 16px;
}

.subs-select-price p {
  font-size: 14px;
  color: #817f80;
  opacity: 0.7;
  margin-bottom: 0;
  margin-top: 15px;
}

.subs-select-duration {
  min-width: 55%;
}

.subs-select-duration .form-control {
  margin-bottom: 21px;
  font-weight: 600;
  color: #888;
  height: 45px;
}

.subs-select-duration p {
  margin-bottom: 0;
}

.subscription-tab-price {
  font-weight: bold;
  font-size: 24px;
}

.subscription-tab-price span {
  font-size: 16px;
}

.subs-plan-duration {
  background-color: #f9f9f9;
  padding: 25px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.subs-plan-duration p {
  margin-bottom: 12px;
}

.subs-plan-duration p:last-child {
  margin-bottom: 0;
}

.subs-plan-price {
  text-align: right;
  font-weight: 600;
}

.subs-plan-duration strong {
  font-size: 16px;
}

.trmncndtions {
  font-size: 12px;
}

.form-control.StripeElement {
  height: 49px;
  padding-top: 16px;
}

.plan-status {
  display: flex;
  align-items: center;
}

.plan-status-txt {
  margin-right: 28px;
  color: #6b6b6b;
  text-align: center;
}

.plan-status-box {
  margin: 0px 0 4px;
  background-color: rgba(203, 242, 242, 0.75);
  padding: 6px 7px;
  font-weight: bold;
  font-size: 10px;
  display: inline-block;
  border-radius: 6px;
  line-height: 13px;
  color: #000000;
}

.plan-status-txt p {
  margin: 0;
  font-size: 10px;
  color: #fff;
}

.plan-status-txt.expired .plan-status-box {
  background-color: rgba(152, 16, 15, 0.15);
}

.plan-status-txt.expired p {
  color: #98100f;
}

.expired {
  color: #98100f;
}

.plan-status-header {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.plan-status-header .plan-status {
  border-right: solid 2px #fff;
  padding-right: 18px;
  margin-right: 13px;
}

.subscription-tax .subs-select-price {
  display: flex;
}

.subscription-tax .subs-select-price p {
  margin-top: 0 !important;
  margin-right: 30px;
  color: #817f80;
  font-size: 14px;
  opacity: 1;
}

.subscription-tax .subs-select-price .subs-price {
  font-size: 16px;
}

.subscription-tax .subscription-start {
  color: #817f80;
  font-size: 14px;
  margin: 0;
  text-align: right;
  flex: 0 0 55%;
}

.subscription-duration.subscription-due-today {
  justify-content: flex-end !important;
  padding: 20px 25px !important;
  background-color: transparent !important;
  margin-top: 0 !important;
}

.subscription-due-today p {
  margin-bottom: 0;
  margin-right: 30px;
  font-weight: bold;
  font-size: 14px;
}

.selectgroup-item.current--plan {
  position: relative;
  margin-left: 27px !important;
}

.selectgroup-item.current--plan .selectgroup-button {
  border: 1px solid rgba(48, 205, 204, 0.5);
  background-color: #f8f8f8;
  padding-left: 30px;
  padding-right: 30px;
}

.selectgroup-item.current--plan .current {
  border: solid 1px #30cdcc;
  padding: 0px 4px;
  font-size: 12px;
  position: absolute;
  left: -27px;
  z-index: 1;
  top: 13px;
  background-color: #fff;
}

.subscribe--plans {
  margin-top: 4px;
}

.plan-descptn {
  min-height: 139px;
  padding: 10px 0 0;
  border-bottom: solid 1px #ddd;
  margin-bottom: 11px;
}

.selectgroup {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.planlabel {
  font-size: 16px;
  font-weight: 600;
}

.subscribe--plans .selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  font-weight: 400 !important;
  margin-right: 23px;
}

.subscribe--plans .selectgroup-item:last-child {
  margin-right: 0;
}

.subscribe--plans .selectgroup-input:checked+.selectgroup-button {
  z-index: 1;
  color: #4a4a4a;
  font-weight: 600;
  background: #cbf2f2;
  border: 2px solid #30cdcc;
}

.subscribe--plans .selectgroup-item:not(:first-child) .selectgroup-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.subscribe--plans .selectgroup-item:not(:last-child) .selectgroup-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.subscribe--plans .selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.subscribe--plans .selectgroup-button {
  display: block;
  border: 1px solid #d0d0d0;
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 0;
  color: #4a4a4a;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px;
  line-height: 1.9rem;
  min-width: 2.375rem;
  box-shadow: 2px 2px 2px #f2f2f2;
}

.subscribe--plans .selectgroup-button>span {
  font-size: 14px;
}

.subscribe--plans .selectgroup-button:hover {
  background: #cbf2f2;
  border-color: #cbf2f2;
}

.plan-descptndetails {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.plan-descptndetails .plan-select-tate {
  width: 136px;
}

.plan-descptndetails .plan-select-tate .css-yk16xz-control {
  border: none;
}

.plan-descptndetails .plan-select-tate .css-1pahdxg-control {
  border: none;
}

.plan-descptndetails .plan-select-tate.css-govwq4-menu {
  margin-top: -2px;
}

.plan-descptndetails .plan-select-tate .css-1wa3eu0-placeholder {
  color: #4a90e2 !important;
  white-space: nowrap !important;
}

.plan-descptndetails .plan-select-tate .css-1uccc91-singleValue {
  color: #4a90e2 !important;
  white-space: nowrap;
}

.plan-descptndetails .plan-select-tate .css-tlfecz-indicatorContainer {
  padding: 8px 0;
}

.plan-descptndetails .plan-select-tate svg path {
  fill: #4a90e2 !important;
}

.plan-descptndetails .plan-txss {
  margin-left: 20px;
}

.plan-descptndetails .plan-select-tate select {
  border: none;
  background-color: #fff;
  color: #4a90e2;
  font-weight: 600;
}

.plan-descptndetails .plan-select-tate select:focus {
  border: none;
  outline: 0;
}

.totalduetoday {
  font-size: 14px;
  font-weight: 600;
}

.plan-totalprices {
  font-size: 16px;
  font-weight: 600;
}

.plan-totalprices span {
  font-size: 24px;
  font-weight: 600;
}

.plan-totalprices {
  margin-left: 20px;
}

.sub-term-cond {
  margin-bottom: 105px;
}

.profile-flag.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url(../images/ic_check.svg);
}

.profile-flag.custom-checkbox .custom-control-input:disabled~.custom-control-label::after {
  background-color: #dfdcdf;
}

.profile-flag.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #dfdcdf;
}

.profile-flag .custom-control-input:checked~.custom-control-label::before {
  border-color: #30cdcc;
}

.profile-flag.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.profile-flag .custom-control-input:disabled~.custom-control-label::before,
.profile-flag .custom-control-input[disabled]~.custom-control-label::before {
  background-color: #dfdcdf;
}

/****----Ports Checkbox-----****/
.qt-activity-name .titleName .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url(../images/ic_check.svg);
}

.qt-activity-name .titleName .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #30cdcc;
}

.qt-activity-name .titleName .custom-control-input:checked~.custom-control-label::before {
  border-color: #30cdcc;
  background-color: #30cdcc;
  border-radius: 0;
}

.optional-item-tr .custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.optional-item-tr .custom-control-input:disabled~.custom-control-label::before,
.optional-item-tr .custom-control-input[disabled]~.custom-control-label::before {
  background-color: #dfdcdf;
}

/****----Ports Checkbox CLOSE-----****/

.optional-item-tr td,
.optional-item-tr .custom-control-label {
  color: #121213 !important;
}

.optional-item-tr .custom-control-input:disabled~.custom-control-label,
.optional-item-tr .custom-control-input[disabled]~.custom-control-label {
  color: rgba(129, 127, 128, 0.65);
}

.organization-flag {
  margin-top: 13px;
}

.organization-flag .pl-2 img {
  position: relative;
  top: -2px;
}

.apply-coupon .totalduetoday {
  position: relative;
}

.apply-coupon .errorValidationMessage {
  position: absolute;
  z-index: 1;
  bottom: -22px;
}

.apply-coupon button {
  border: 0 !important;
  border-radius: 0;
  padding: 7px 10px;
  width: 100px;
  background-color: #979797;
  color: #fff !important;
}

.apply-coupon button:hover,
.apply-coupon button:focus {
  background-color: #777777;
  color: #fff !important;
}

.coupon-discount {
  padding: 1px 10px;
  /* background-color: #eee; */
}

.asignleadselect {
  position: relative;
}

.asignleadselect .rvwqt-note {
  position: absolute;
  top: 100%;
  margin-top: 7px !important;
  line-height: 1.3;
}

.hide {
  display: none !important;
}

.w-78 {
  width: 78%;
}

@media screen and (max-width: 1399px) {

  .floating-input:focus~label,
  .floating-input:not(:placeholder-shown)~label,
  .floating-select:focus~label,
  .floating-select:not([value=""]):valid~label {
    left: 7px;
  }

  .css-douzob-ValueContainer {
    padding: 0 9px;
  }

  .select-floating-label.css-1wa3eu0-placeholder {
    padding: 0 7px;
    margin: 0;
    left: 3px;
    white-space: nowrap;
  }

  .floating-label label {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1199px) {
  .qt-activity-name {
    min-width: 310px;
  }

  .titleName {
    min-width: 310px;
  }

  .qt-fees {
    width: 250px;
  }

  .dashboard-task-table .task--subject {
    min-width: 280px;
    max-width: 100%;
  }

  .fixed-information-length {
    width: 277px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbl-tabbing {
    display: none;
  }

  .floating-label label {
    width: calc(100% - 26px);
    left: 8px;
  }

  .floating-label input,
  .floating-label select,
  .floating-label textarea {
    padding: 16px 17px;
  }

  .lead-page-hdr.fixed--header {
    padding-top: 72px;
  }

  .lead-page-hdr.fixed--header {
    position: static;
  }

  .page-title .title--col .title .title--text {
    max-width: 508px;
  }

  .leadDetail_form.card-body .form-group {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .desc-cstmwidth+.col-xl-2 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 1024px) {
  .smart-table tr .close-icn {
    opacity: 1;
    pointer-events: all;
    transition: all ease 0.3s;
  }
}

@media screen and (max-width: 991px) {
  .plan-status {
    justify-content: flex-end;
    width: 100%;
  }

  .main-site {
    height: auto;
    min-height: 100vh;
    padding-bottom: 60px;
  }

  .main-site.login_signup,
  .main-site.profie-page {
    padding-bottom: 0;
  }

  .plan-expire-alert {
    display: none;
  }

  .yourid-wrap,
  .paymentnotice-wrap {
    flex-direction: column;
  }

  .yourchangeid {
    margin-top: 20px;
  }

  .paymentnotice-wrap .noticecol {
    margin-bottom: 12px;
  }

  .leads-container .leads-container_row .leads-col .leads-col_body {
    margin-right: 0;
  }

  .leads-container .leads-container_row.lead-stage-adjust .leads-col {
    min-height: 2px !important;
  }

  .rvwqt-name,
  .rvwqt-deposit {
    min-height: 1px;
  }

  .quote-bizz-details {
    text-align: left;
    margin-top: 20px;
  }

  .quote-bizzname {
    font-size: 22px;
    padding-left: 20px;
    line-height: 23px;
  }

  .deposit-reqr .deposit_Usd {
    margin-left: 18px;
  }

  .line-item-del {
    padding-left: 9px;
  }

  .deposit-reqr {
    flex-wrap: wrap;
  }

  .deposit-reqr>p {
    min-width: 100%;
    margin-bottom: 10px !important;
  }

  .deposit-reqr .form-group {
    margin: 0 !important;
  }

  .card-header .input-edit-wrap {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .create-qoute--cards.create-form .card-header .floating-label {
    width: 100%;
  }

  .rvwqt-final,
  .rvwqt-signature {
    flex: 0 0 50%;
  }

  .desc-cstmwidth+.col-xl-2 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .descOrder-mbl {
    order: 1;
  }

  .email--to>span,
  .email--subject>span {
    position: static;
  }

  .message-sender-wrap {
    padding: 0 23px;
  }

  .email--from,
  .access--code {
    display: none;
  }

  .emailField {
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .email--subject .emailField .floating-input,
  .email--to .emailField .floating-input {
    min-width: 100%;
  }

  .emailField .form-group {
    margin-right: 0;
    width: 100%;
  }

  .related-toDo {
    width: 100%;
    min-width: auto;
    flex: 0 0 100% !important;
  }

  .quote-total-row tr,
  .quote-total-row {
    padding: 0 !important;
  }

  .rvwqt-row {
    flex-wrap: wrap;
  }

  .rvwqt-name {
    flex: 0 0 100%;
    border-right: 0;
    margin: 0;
    padding: 0 20px;
  }

  .rvwqt-name:after {
    display: none;
  }

  .rvwqt-deposit,
  .rvwqt-download {
    flex: 0 0 50%;
    margin: 20px 0;
    padding: 0 20px;
  }

  .rvwqt-deposit:nth-child(3) {
    border-right: 0;
  }

  .rvwqt-btns {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .rvwqt-btns .btn {
    margin: 8px 15px !important;
  }

  .rvwqt-btns .btn-primary {
    width: 100%;
    margin-top: 20px;
  }

  .rvwqt-note {
    padding: 0 0px 10px;
  }

  .qt-desc {
    order: 1;
  }

  .qt-activity-name,
  .titleName,
  .qt-fees {
    flex: 0 0 50%;
    min-width: 100px;
    width: auto;
  }

  .qt-subtotal,
  .qt-subtotal+.qt-fees,
  .qt-total,
  .qt-total+.qt-fees {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .qt-fees {
    text-align: right;
  }

  .quote-total-row .qt-fees {
    flex: 0 0 30%;
  }

  .qt-qttotal {
    width: auto;
    flex: 0 0 70%;
    min-width: 100px;
  }

  .quote-total-row>td {
    flex: 0 0 100%;
  }

  .qt-colblank {
    display: none;
  }

  .quote--create-form {
    padding: 10px 20px;
  }

  .lineitemwidth {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .desc-cstmwidth {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .line-item--row {
    flex-wrap: wrap;
    padding: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .add-fee-row {
    padding: 0;
  }

  .line-item--row .line-item-del {
    width: 100%;
    padding: 0;
    text-align: right;
  }

  .line-item-del .close-icn {
    display: inline-flex;
    align-items: center;
  }

  .line-item-del .close-icn span {
    color: var(--danger);
  }

  .card-header .css-1pahdxg-control {
    height: 41px !important;
  }

  .card-header .css-yk16xz-control,
  .title-elems .css-yk16xz-control,
  .title-elems .css-1pahdxg-control {
    height: 41px !important;
  }

  .title-elems .css-govwq4-menu {
    bottom: 100%;
    top: auto;
  }

  .title-elems .cstselectmin-w {
    min-width: 114px;
  }

  .bookingResp_table table.table tbody tr {
    padding-left: 42px;
  }

  .empty-booking-table.table tbody tr {
    padding-left: 0 !important;
  }

  .bookingResp_table table.table.table-lg tbody td:first-child {
    position: absolute;
    left: 0;
    top: 8px;
  }

  .bookingResp_table table.table.table-lg tbody td.bg-white:first-child {
    position: static !important;
    flex: 0 0 100%;
  }

  .bookingResp_table table.table.table-lg td.booking--date {
    justify-content: flex-start;
  }

  .leadDetail_form.card-body .form-group {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .floating-label label {
    width: calc(100% - 31px);
    left: 18px;
  }

  .booking--date {
    max-width: 40%;
  }

  .contacts--table tbody td:first-child {
    width: 60px;
  }

  .booking--table tbody td:first-child {
    width: 29px;
  }

  .booking--name {
    flex-basis: 50%;
  }

  .booking--location,
  .booking--amount,
  .booking--org {
    max-width: 50% !important;
    flex-basis: 50%;
  }

  .card-header_btns .css-yk16xz-control {
    height: 41px !important;
  }

  .main-card .btn.btn--card-collapse,
  .leads-container .btn.btn--card-collapse {
    padding: 20px;
  }

  .justify-content-center.title-elems {
    justify-content: center !important;
  }

  .btn-mr {
    margin-right: 35px !important;
  }

  .logo_imgg {
    margin-bottom: 15px;
  }

  .font-small {
    font-size: 13px;
    margin-left: 10px;
    white-space: nowrap;
  }

  .smart-table.calendar--table tbody td:first-child .form-check {
    top: -2px;
  }

  .calendar--table td,
  .booking--table td {
    padding: 8px 20px !important;
  }

  .calendar--table tbody td:first-child {
    width: 60px;
    align-self: baseline;
  }

  .empty-leads {
    min-height: 250px;
    text-align: center;
  }

  .new-lead-timeline .timeline_row .timeline-cols.active span:before {
    content: "";
    position: absolute;
    top: -22px;
    left: -17px;
    display: inline-block;
    width: 0;
    height: 0;
    z-index: -1;
    border-top: 22px solid transparent;
    border-left: 16px solid #cbf2f2;
    border-bottom: 22px solid transparent;
  }

  .new-lead-timeline .timeline_row .timeline-cols.active span {
    display: inline-block;
    position: absolute;
    left: 100%;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 16px solid #30cdcc;
    border-bottom: 22px solid transparent;
    z-index: 1;
  }

  .closed-container_header {
    padding: 0 20px;
  }

  .closed-container_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .closed-container_row .dragable--card {
    background-color: #fff !important;
    margin-bottom: 15px;
  }

  .closed-container_row .dragable--card {
    border-radius: 4px;
    flex: 0 0 46%;
    margin-right: 0;
  }

  .leader--source {
    text-align: right;
    margin-bottom: 0px;
    padding: 10px 10px;
  }

  .new-lead-timeline .timeline_row .timeline-cols {
    flex: 1 0 auto;
    padding-left: 28px;
  }

  .new-lead-timeline .timeline_row .timeline-cols span {
    display: inline-block;
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 16px solid #d8d8d8;
    border-bottom: 22px solid transparent;
    z-index: 1;
  }

  .new-lead-timeline .timeline_row .timeline-cols:hover span:before {
    border-left: 16px solid #d8d8d8;
  }

  .new-lead-timeline .timeline_row .timeline-cols.active span,
  .new-lead-timeline .timeline_row .timeline-cols:hover span {
    border-left: 16px solid #30cdcc;
  }

  .new-lead-timeline .timeline_row .timeline-cols.active:hover span:before {
    border-left: 16px solid #cbf2f2;
  }

  .new-lead-timeline .timeline_row .timeline-cols span:before {
    content: "";
    position: absolute;
    top: -22px;
    left: -17px;
    display: inline-block;
    width: 0;
    height: 0;
    z-index: -1;
    border-top: 22px solid transparent;
    border-left: 16px solid #fff;
    border-bottom: 22px solid transparent;
  }

  .new-lead-timeline .timeline_row .timeline-cols.completed span {
    border-left: 16px solid #30cdcc;
  }

  .new-lead-timeline .timeline_row .timeline-cols i,
  .new-lead-timeline .timeline_row .timeline-cols em {
    font-style: normal;
  }

  .stage--notes {
    display: none;
  }

  .customize-stages-info {
    padding: 10px 15px;
    background-color: #fff;
    margin-bottom: 5px;
  }

  .moveupdown {
    flex-direction: column;
  }

  .fixed--header {
    padding-top: 150px;
  }

  .fixed--header .site-navbar {
    height: auto;
  }

  .fixed--header .contact--header {
    top: 70px;
    border-bottom: solid 1px #e9e9e9;
    display: flex;
    min-height: 80px;
    align-items: center;
  }

  .fixed--header.fixed--elements .contact--header {
    top: 70px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.07);
    box-shadow: 0 5px 2px rgba(0, 0, 0, 0.07);
  }

  .fixed--header.fixed--elements .site-navbar {
    opacity: 1;
    height: auto;
    pointer-events: all;
  }

  .stage--preview-container {
    border: none;
  }

  .leads-container.stage--preview-container .leads-container_row .leads-col .leads-col_header {
    padding: 0;
  }

  .leads-container.stage--preview-container .leads-container_row .leads-col {
    margin-bottom: 0;
  }

  .leads-container .leads-container_row {
    display: block;
    width: 100%;
  }

  .leads-container .leads-container_row .leads-col {
    max-width: 100%;
    margin-bottom: 5px;
    height: auto;
    min-height: 2px !important;
  }

  .leads-container .leads-container_row .leads-col .leads-col_header {
    padding: 0;
    border-radius: 0;
  }

  .leads-container .leads-container_row .leads-col .leads-col_header .btn--leads-collapse {
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #4a4a4a;
    padding: 20px;
  }

  .leads-container .leads-container_row .leads-col .leads-col_body .leads-col_body--wrap {
    padding: 0;
  }

  .dragable--card {
    box-shadow: none;
    margin-bottom: 0;
    border-radius: 0;
  }

  .dragable--card:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  .close {
    right: 12px;
  }

  .close:focus {
    outline: 0 !important;
  }

  .dropdown--search_wrapper .form-control {
    margin-top: 20px;
  }

  .dropdown--search .dropdown--search_btn {
    border: none;
    padding: 8px 10px;
  }

  .dropdown--search .dropdown--search_btn img {
    width: 23px;
  }

  .contacts--table td {
    padding: 8px 10px !important;
  }

  .contact--no a {
    display: inline-block;
    padding: 10px;
  }

  .contact--id_mbl,
  .contact--no img {
    display: block;
  }

  .contacts--table tbody td.contact--name {
    flex: 1 0 auto;
    padding-left: 0 !important;
    max-width: 100%;
  }

  .contact--id,
  .biz--name,
  .lead--name,
  .contact--no span {
    display: none;
  }

  .main-card {
    /*- margin-bottom: 5px;*/
  }

  .page-title .title--col {
    justify-content: space-between;
  }

  .page-title .title--col h2 {
    font-size: 20px;
  }

  .smart-table {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0 !important;
  }

  .smart-table thead {
    display: none;
  }

  .smart-table tbody {
    flex: 0 0 100%;
    width: 100%;
  }

  .smart-table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
    align-items: flex-start;
    position: relative;
  }

  .smart-table tbody tr td,
  .smart-table.table-lg tbody tr td {
    padding: 8px 8px;
  }

  .task--status,
  .task--todo {
    flex: 0 0 50%;
  }

  .task--subject,
  .task--relatedto {
    flex: 0 0 100%;
  }

  .site-body {
    padding-bottom: 60px;
  }

  .biz-snapshot .biz-snapshot_inner {
    padding: 10px;
  }

  .footer-col_right .social-btns,
  .footer-col_left {
    display: none;
  }

  .site-footer {
    padding: 6px 0;
    width: 100%;
  }

  .site-footer-m0+.site-footer {
    margin-bottom: 0;
  }

  .copyright {
    margin-top: 4px;
    font-size: 12px;
    text-align: center;
  }

  .title-elems {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    width: 100%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    gap: 10px;
  }

  .dropdown-toggle--mbl .dropdown-toggle {
    padding: 10px 18px;
    border: none;
  }

  .dropdown-toggle--mbl .dropdown-toggle::after {
    display: none;
  }

  .middle-section {
    padding: 5px 0;
  }

  .btn--card-collapse {
    display: flex;
    border-bottom: solid 1px #eee;
  }

  .btn--card-collapse[aria-expanded="false"] img {
    transition: all ease 0.3s;
    transform: rotateZ(0);
  }

  .btn--card-collapse[aria-expanded="true"] img {
    transition: all ease 0.3s;
    transform: rotateZ(90deg);
  }

  .site-header {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .site-navbar {
    padding: 0;
    border-bottom: none;
  }

  .site-navbar .navbar-collapse {
    position: relative;
    height: calc(100vh - 104px);
  }

  .site-navbar .navbar-collapse .navbar-toggler {
    position: absolute;
    right: 11px;
    top: 15px;
    box-shadow: none;
    outline: 0;
    border: none;
    height: 43px;
    padding-top: 0;
    z-index: 1;
  }

  .site-navbar .navbar-nav {
    margin-top: 32px;
  }

  .site-navbar .navbar-nav .nav-item .nav-link {
    border: none;
    border-bottom: solid 5px #eee !important;
    font-size: 18px;
    padding-left: 40px;
    background-color: #fff;
  }

  .site-navbar .navbar-nav .nav-item.active .nav-link {
    color: var(--selected);
  }

  .site-navbar .navbar-nav .nav-item .nav-link .count {
    display: inline-block;
    font-size: 14px;
    color: #4a4a4a;
    font-weight: normal;
  }

  .user-login_nav {
    position: absolute;
    right: 6px;
    top: 9px;
  }

  .site-header .site-header_wrap {
    justify-content: center;
  }

  .site-header .user-login_dropdown .avatar-img {
    margin: 0;
    height: 25px;
  }

  .site-header .navbar-brand {
    margin-right: 0;
  }

  .site-header .user-login_dropdown .dropdown-toggle:after {
    display: none;
  }

  .site-header .user-login_dropdown .dropdown-toggle span {
    display: none;
  }

  .site-header .navbar-toggler {
    display: inline-block;
  }

  .site-header .navbar-toggler {
    box-shadow: none;
    outline: 0;
  }

  .smart-table tbody td:first-child .form-check {
    top: 10px;
  }

  .main-card .card-header {
    justify-content: flex-end !important;
    padding: 10px 20px !important;
  }

  .main-card .card-headers {
    /* justify-content: flex-end !important; */
    padding: 10px 20px !important;
  }

  .main-card .card-header h2 {
    display: none;
  }

  .main-card .card-header .card-header_btns {
    justify-content: flex-end !important;
    width: 100%;
  }

  .main-card .card-header .card-header_btns span {
    display: none;
  }

  .fixed--header.fixed--elements .site-header .navbar-brand {
    font-size: 35px;
  }

  .page-title .title--col .breadcrumb {
    display: none;
  }

  .notes--table td[class="bg-white"],
  .tasks--table td[class="bg-white"] {
    width: 100%;
  }

  .tasks--table tr td.text-right,
  .notes--table tr td.text-right {
    position: absolute;
    right: 0;
    top: 8px;
  }

  .visible-entries {
    text-align: center;
    margin: 0 15px 10px;
  }

  .site-pagination {
    margin: 0 auto;
  }

  .contacts--table tbody td.bg-white {
    width: 100% !important;
    max-width: 100% !important;
  }

  .contacts--table tbody td.bg-white~tr {
    background-color: red;
  }

  .notes--table tr td:nth-child(2) {
    width: 100% !important;
  }

  .site-pagination .pagination {
    justify-content: center;
  }

  .btn {
    padding: 0.47rem 16px;
  }

  .contact-detail--wrap {
    padding: 0 20px;
  }

  .title-elems .dropdown-toggle--mbl {
    position: absolute;
    right: 7px;
    top: 10px;
  }

  .dashboard-business-card .card-body {
    padding: 0 15px;
  }
}

.footer-row {
  width: 100%;
}

.footer-row .twitter {
  color: rgb(18, 179, 243);
}

ul.footer-row.footer-social-icn {
  margin-left: auto;
  width: 195px;
}

ul.footer-row.footer-social-icn li {
  padding: 0px 0;
}

ul.footer-row.footer-social-icn li:last-child a {
  border: none;
  padding: 0;
}

.footer-row i {
  font-size: 15px;
  padding: 10px;
  margin: 6px;
  background-color: white;
  border-radius: 50px;
}

.footer-row .facebook {
  color: rgb(53, 53, 184);
  width: 35px;
  display: inline-flex;
  justify-content: center;
}

.footer-row .pinterest {
  color: red;
}

.footer-row .instagram {
  color: red;
}

.contact-service-set {
  display: flex;
  align-items: flex-start;
}

.contact-service-set .floating-label {
  width: calc(100% - 100px);
  margin-bottom: 0;
}

#contact-setting-modal .modal-body {
  padding: 25px 10px 10px;
}

#contact-setting-modal .modal-dialog {
  max-width: 720px;
}

.contactformli li {
  padding-bottom: 10px;
}

.contactformguidimg img {
  width: 410px;
}

.contactformguidimg img {
  cursor: pointer;
}

.contact-set-img {
  width: 100%;
}

.formbox {
  background: #fff !important;
  display: inline-block;
  padding: 30px !important;
  width: 100%;
}

.formbox .quote--create-form {
  padding: 0;
}

.formbox .floating-label input::-webkit-input-placeholder,
.formbox .floating-label select::-webkit-input-placeholder,
.formbox .floating-label textarea::-webkit-input-placeholder {
  opacity: 1 !important;
}

.formbox .line-item--row2 {
  display: inherit;
  margin-top: 10px;
}

.max-width--400 {
  max-width: 400px;
}

.max-width--115 {
  max-width: 120px;
  margin: auto;
}

.m250 {
  max-width: 250px;
}

.line-item--row2 .floating-label input,
.line-item--row2 .floating-label select {
  height: 44px;
  padding: 12px 16px;
}

.line-item--row2 .selectBOx .floating-label {
  height: 50px;
  margin: auto !important;
}

.USD {
  display: flex;
  justify-content: center;
  align-items: center;
}

.USD input {
  font-weight: bold;
  padding: 0;
  text-align: center;
  border: none;
  font-size: 16px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-color: transparent !important;
}

.USD input[type="number"]::-webkit-inner-spin-button,
.USD input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.USD p {
  font-size: 14px;
  color: #9b9b9b;
}

.line-item--row2 .selectBOx.USD .floating-label {
  margin: 0px !important;
  max-width: 85px;
}

.USD .d-flex {
  font-weight: 700;
  /* justify-content: flex-start; */
  /* margin: auto !important; */
  font-size: 16px;
  /* width: 100%; */
  text-align: center;
  display: inline-flex !important;
}

.line-item--row2 .line-item-del {
  position: absolute;
  right: 0;
  top: 13px;
  right: 10px;
}

.tablehead {
  width: 100px;
}

.qt-activity-name strong {
  font-size: 16px;
  color: #121213;
  margin-bottom: 10px;
  display: inline-block;
}

.titleName strong {
  font-size: 16px;
  color: #121213;
  margin-bottom: 10px;
  display: inline-block;
}

.qt-activity-name p {
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
}

.titleName p {
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
}

.smart-table.table thead th {
  font-size: 16px;
  color: #121213;
  border: none;
  white-space: nowrap;
}

.table th {
  white-space: nowrap;
}

.description-list-item {
  width: 80%;
}

.card-header.padd0 {
  padding-top: 0px !important;
}

.infoICon .floating-label {
  display: inline-flex;
  align-items: center;
}

.infoICon .floating-label img {
  margin-left: 5px;
}

.tool-tip {
  display: inline;
  position: relative;
  z-index: 9;
}

/* tooltip css */
.tool-tip:hover:after {
  display: flex;
  justify-content: center;
  background: #000;
  border-radius: 5px;
  color: #fff !important;
  content: attr(title);
  margin: 20px auto 0px;
  font-size: 12px;
  padding: 12px;
  width: 150px;
  position: absolute;
  margin-left: -60px;
  text-align: center;
}

.tool-tip:hover:before {
  border: solid;
  border-color: #000 transparent;
  border-width: 0 6px 12px 6px;
  content: "";
  left: 35%;
  bottom: -20px;
  position: absolute;
}

/* booking tootip css */
.booking--date .tool-tip:hover:after {
  content: "Appointment" !important;
}

/* view profile business profile tootip css */
.viewProfile-tooltip .tool-tip:hover:after {
  content: "Upload a business banner that you want to include in Quotes, Contracts or Email notifications." !important;
  margin-left: 0;
  margin-bottom: 10px;
}

/* addBasicContracts tootip css */
.addBasicContracts-tooltip .tool-tip:hover:after {
  content: "Customer must respond within this period.The link to Contract will expire at the end of this duration." !important;
  margin-left: 0;
  margin-bottom: 10px;
}

/* addBasicContracts tootip css */
.addBasicQuote-tooltip .tool-tip:hover:after {
  content: "Customer must respond within this period.The link to quote will expire at the end of this duration." !important;
  margin-left: 0;
  margin-bottom: 10px;
}

.form-group .tool-tip:hover:before {
  bottom: -24px;
}

.btn-primary.dropdown-toggle {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff;
}

.appointment--date-time {
  flex-grow: 1;
}

.appointment-info-badge {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  color: #000;
  background-color: #bcbcbc;
  font-weight: bold;
  text-align: center;
  line-height: 14px;
  font-size: 11px;
  margin-left: 17px;
  cursor: pointer;
}

.list-view-icon,
.grid-view-icon {
  /* fill:#999997 ; */
  fill: #30cdcc;
  cursor: pointer;
}

.list-view-icon.active,
.grid-view-icon.active {
  fill: #30cdcc;
  display: none;
  cursor: default;
}

.list-view-icon:hover,
.grid-view-icon:hover {
  /* fill: #636363 */
}

.list-view-icon.active:hover,
.grid-view-icon.active:hover {
  fill: #30cdcc;
}

.tab-section .ant-tabs-tab {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #121213;
}

.tab-section .ant-tabs {
  padding: 1.5rem;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  position: relative;
}

.tab-section .ant-btn.btn.btn-secondary {
  height: auto;
}

.tab-header {
  position: absolute;
  right: 1.5rem;
  top: 25px;
}

.taskCollapse,
.tab-section .card {
  position: inherit !important;
}

.tab-header .btn {
  /* padding: 8px 26px; */
}

.tab-section .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #121213;
}

.tab-section .ant-tabs-ink-bar {
  background-color: #30cdcc;
  height: 3px !important;
  border-radius: 5px 5px 0 0;
}

.tab-section .ant-tabs-nav::before {
  display: none;
}

.tab-section #nav-Tasks {
  position: inherit;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 30px;
}

.width-150 {
  width: 135px;
}

span.tab-pane {
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #121213;
}

.task-countt {
  color: #121213;
  font-weight: normal;
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #121213;
}

.appoitment-form .modal-lg,
.appoitment-form .modal-xl {
  max-width: 600px;
}

.appoitment-form .css-1wa3eu0-placeholder {
  font-weight: 400;
}

.delete-dummy-data h4 {
  color: #817f80;
  font-weight: 500;
  margin-bottom: 20px;
}

.delete-dummy-data .modal-header {
  padding: 0;
}

.delete-dummy-data .modal-dialog {
  max-width: 499px;
}

.delete-dummy-data .btn.btn-secondary {
  color: #fff;
  background: #fb9a00;
  max-height: 45px;
}

.delete-dummy-data .btn.btn-default {
  border: 1px solid #979797;
  background: #999997;
  color: #fff;
}

.delete-dummy-data .modal-body p,
.delete-dummy-data .form-group label {
  color: #6b6b6b;
}

.delete-dummy-data .custom-control {
  margin-bottom: 10px;
}

.delete-dummy-data .close span {
  top: 0;
}

.delete-dummy-data .error {
  font-size: 15px;
}

.floating-label.related-to {
  border: 1px solid rgb(177 177 177 / 50%) !important;
  border-radius: 6px !important;
  height: 41px !important;
  color: #817f80 !important;
  padding-top: 10px;
  padding-left: 16px;
  position: relative;
}

.related-to label {
  position: absolute;
  top: -8px;
  width: auto;
  left: 16px;
}

.calender-list {
  padding-top: 1.25rem;
}

.appointment-sec {
  opacity: 1 !important;
  display: block !important;
}

.appointment-sec .main-card .card-header h2 {
  display: block !important;
}

.appointment-sec .smart-table tbody tr {
  justify-content: inherit !important;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #30cdcc;
  color: #fff;
}

.rbc-toolbar-label {
  font-size: 18px;
  font-weight: bold;
}

.leadoptionselect select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.leadoptionselect svg {
  position: absolute;
  right: 8px;
  pointer-events: none;
  top: 15px;
  color: #ccc;
}

.button-align-center {
  justify-content: center
}

.show-payment-option-model {
  max-width: 100% !important;
}


.editorbox {
  /* position: absolute;
width: 502px;
height: 244px;
left: 1375px;
top: 236px; */


}

.subject-text {
  display: flex;
  align-items: center;
}

.subject-text input {
  max-width: 320px;
  border: 1px solid #BCBCBC;
}

.auto-reply-model h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #817F80;
  margin-bottom: 15px;
}

.auto-reply-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6B6B6B;
  margin-bottom: 20px;
}

.auto-reply-subject {
  margin-right: 10px;
}

.enable-checkbox {
  margin-top: 15px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #817F80;
}

.enable-checkbox input[type="checkbox"] {
  border: 1.5px solid #BCBCBC;
  width: 20px;
  height: 20px;
  margin-right: 14px;
}

.auto-reply-model .modal-header {
  padding: 0;
  padding-top: 10px;
}

.auto-reply-model .modal-dialog-centered {
  max-width: 570px;
}

/* 20-12-2022 */

.small-text {
  font-weight: 400;
  font-size: 14px;
  font-family: Arial;
  line-height: 20px;
  color: #817F80;
  max-width: 520px;
  text-align: left;
  margin-bottom: 30px;
}

.small-text a {
  font-weight: bold;
  color: #4A90E2;
}

.email-integration .custom-control-label {
  /* font-family: 'Helvetica Neue'; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #817F80;
}

.email-template {
  background: #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.email-head {
  padding: 10px;
  align-items: center;
}

.top-btn-left {
  display: flex;
  gap: 5px;
}

.email-btn {
  background: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  display: inline-flex;
  transition: inherit;
  align-items: center;
  gap: 3px;
  padding: 3px 5px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  color: #555555 !important;
}

.email-btn .material-symbols-outlined {
  font-size: 16px;
}

.email-btn.btn-disabled {
  background: #efefef;
  border-color: #efefef;
  pointer-events: none;
  color: #ababab;
}

.email-btn:hover {
  color: #FFF !important;
  text-decoration: none !important;
}

.email-btn img {
  height: 15px;
  opacity: 0.8;

}

.email-btn {
  outline: none !important;
}

.email-btn.btn-disabled img {
  opacity: 0.3;
}

.email-template-design {
  background: #fff;
  padding: 0;
}

.top-right {
  display: flex;
  gap: 10px;
  margin-right: 6px;
}

.top-right select {
  background: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  color: #555555;
  min-width: 100px;
}

.top-right input {
  background: #fff;
  border: 1px solid #fff;
  font-size: 12px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  color: #555555;
  height: 26px;
  min-width: 100px;
}

.top-right input[type=search] {
  outline-offset: 0;
  -webkit-appearance: none;
  padding-left: 25px;
  padding-right: 16px;
  max-width: 195px;
}

.top-right input:focus-visible {
  outline: none;
}

.search-box {
  position: relative;
}

.search-box .search_icn {
  height: 18px;
  position: absolute;
  left: 5px;
  top: 4px;
}

.search-box .search_cross_icon img {
  height: 18px;
  position: absolute;
  right: 5px;
  top: 4px;
  cursor: pointer;
}

.email-btn:hover {
  background: #fb9a00;
  color: #fff;
  border-color: #fb9a00;
  text-decoration: none;
}

.email-btn:hover img {
  filter: brightness(0) invert(1);
}

.email-content {
  display: flex;
}

.action-btn {
  background: transparent;
  border: none;
  padding: 5px;
  border-radius: 5px;
  outline: 0 !important;
}

.action-btn:hover {
  background: #eee;
}

.action-btn img {
  height: 17px;
  position: relative;
  top: -1px;
}

.email-content .table td,
.email-content .table th {
  border-top: none;
  vertical-align: middle;
  padding: 5px 10px;
  font-size: 15px;
  font-family: arial;
  border-right: none;
  border-left: none;
}

.custom-control-label {
  margin-left: 8px;
}

.email-content .custom-control-label::before {
  height: 16px;
  width: 16px;
}

.email-content .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-position: 1px;
  top: 5px;
  left: -18px;
  background-size: 11px;
}

.h-18 {
  height: 18px;
  margin-left: 5px;
}

.email-sidebar ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

}

.email-sidebar ul li {
  display: inline-block;
  width: 100%;
  margin: 0 !important;

}

.email-sidebar ul li a {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 7px 15px;
  font-family: 'arial';
  color: #817f80;
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1px solid #dee2e6;
}

.email-sidebar ul li.active a,
.email-sidebar ul li:hover a {
  background: #666666 !important;
  color: #fff !important;
}

.email-sidebar ul li img {
  height: 19px;
  opacity: 0.5;
  margin-right: 5px;
}

.email-sidebar ul li.active a img,
.email-sidebar ul li:hover a img {
  opacity: 1;
  filter: brightness(0) invert(1);
}

.email-template-design .container-fluid {
  max-width: 1500px;
}

.email-content .table tbody td:nth-child(2) {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxShadow {
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
}

.create-btns {
  align-items: center;
  display: flex;
  gap: 10px;
}

.create-btns .btn-secondary {
  background: #fff;
  color: #fb9a00;

}

.create-btns button {
  padding: 5px 15px;
}

.create-btns .btn-secondary:hover,
.create-btns .btn-secondary:active {
  border-color: #fb9a00 !important;
  color: #fb9a00 !important;
}

.create-btns .btn-primary:hover {
  background: #fff;
  color: #fb9a00;
}

.compose-head {
  padding: 10px;
  background: #eee;
}

.comp-inner {
  display: inline-flex;
  width: 100%;
}

.comp-inner .avatar {
  width: 65px;
  height: 65px;
  overflow: hidden;
  background: #fff;
  border-radius: 100%;
}

.comp-inner .avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.compose-head h4 {
  font-size: 18px;
  color: #000;
}

.email-from ul {
  padding: 0;
  list-style-type: none;
  font-family: 'arial';
}

.email-from {
  margin-left: 15px;
  flex: 1;
}

.email-from ul span {
  min-width: 60px;
  display: inline-block;
}

.email-from ul li {
  display: flex;
  margin-bottom: 3px;
  gap: 5px;
  color: #817f80 !important;
  font-size: 14px !important;
}

.adduser {
  outline: none !important;
  border: none;
  padding: 0;
  opacity: 0.5;
  background: none;
}

.adduser img {
  height: 19px;
}

.wktextarea {
  border: 3px solid #eee;
  outline: none !important;
}

/* 20-12-2022 */

.input-box input {
  border-bottom: 1px solid #aaa !important;
  padding-left: 0;
  font-size: 12px;
}

.input-box {
  margin-bottom: 3px;
}

.show-cc-bc {
  gap: 10px;
  display: flex;
  justify-content: end;

}

.drop-attach input {
  display: none;
}

.show-cc-bc span {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

.show-cc-bc span:hover {
  text-decoration: none;
}

.drop-attach {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  width: 100%;
  border: 1px dashed #aaa;
  margin-top: 15px;
}

.drop-attach-name span {
  margin-right: 5px;
  padding: 3px 5px 3px 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  max-width: 100%;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;

}

.input-box input::-webkit-input-placeholder {
  /* Edge */
  color: #817f80 !important;
}

.input-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #817f80 !important;
}

.input-box input::placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

label.drop-attach img {
  height: 18px;
  opacity: 0.5;
}

.drop-attach-name button {
  border: none;
  line-height: 12px;
  margin-bottom: 0;
  background: transparent;
}

.drop-attach-name button:focus {
  outline: 0;
}

/* TAGS INPUT CSS  */

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border-bottom: 1px solid #aaa;
  padding-left: 30px;
  /* min-height: 40px; */
  /* padding: 0 8px; */
}

.email-from .input-box input {
  font-size: 14px;
}

.tags-input:focus-within,
.tags-input:focus {
  color: #817f80;
  background-color: none;
}

.tags-input input {
  flex: 1;
  border: none !important;
  /* height: 40px; */
  font-size: 12px;
  padding: 4px 0 0 0;
}

.tags-input input:hover,
.tags-input input:focus {
  outline: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #817f80;
  padding: 3px 5px 3px 10px;
  font-size: 12px;
  list-style: none;
  border-radius: 5px;
  margin: 0 8px 8px 0;
  background: none;
  border: 1px solid #aaa;
}

.tag i {
  cursor: pointer;
}

.taginput-dropdown-scroll {
  max-height: 121px;
  overflow-y: auto;
}

/* .focus-input {
    position: relative;
    padding-top: 20px;
} */

.tags-input .form-floating {
  flex: 1;
}

/* .focus-input .form-floating {
    position: unset;
} */

.tags-input .form-floating input {
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
}

.tags-input.focus-input .form-floating input {
  padding-top: 0;
}

.tags-input .form-floating>label {
  padding-left: 0;
}

.tags-input.focus-input .form-floating>label {
  padding-left: 8px;
}

@media screen and (max-width: 1200px) {

  .table.table-lg td,
  .table.table-lg th {
    padding: 10px;
  }

  .mob-none {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .btn-text {
    font-size: 0;
  }

  .email-btn {
    gap: 0;
  }

  .custom-dropdown .dropdown-menu .btn-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .comp-inner .avatar {
    width: 40px;
    height: 40px;
  }

  .email-from ul li {
    font-size: 12px;
  }

  .email-from ul span {
    min-width: 40px;
    display: inline-block;
  }

  .email-content {
    flex-wrap: wrap;
  }

  .top-right {
    display: none;
  }

  .email-content .col-sm-3 {
    margin-bottom: 20px;
    width: 100%;
  }

  .email-content .col-sm-9 {
    width: 100%;
  }

  .deletedummydata {
    display: none;
  }

  .appointment-sec .card-header h2 {
    font-size: 18px;
  }

  .no--contacts--note {
    min-height: inherit;
  }

  .no--contacts--note h5 {
    margin: 0;
  }

  .tab-section .ant-tabs {
    padding: 1.5rem 15px;
  }

  .rbc-toolbar .rbc-toolbar-label {
    width: 100%;
    padding: 6px 10px;
  }

  .mbHide {
    display: none;
  }

  .mright.mr-15 {
    margin-right: 8px !important;
  }

  .task--status,
  .task--todo {
    flex: 0 0 100%;
  }

  .mycustom-alert .swal2-cancel {
    padding: 11px 10px !important;
  }

  .mycustom-alert .swal2-close {
    right: -5px !important;
  }

  .floating-label label {
    font-size: 14px;
  }

  .user-login_nav .btn-secondary {
    display: none;
  }

  .main-card .btn--card-collapse {
    display: flex;
    white-space: normal;
    text-align: left;
  }

  .payment-modal-header .modal-title {
    font-size: 18px;
  }

  .payment-modal-header {
    flex-wrap: wrap;
  }

  .payment-modal-header .modal-amount {
    font-size: 18px;
  }

  .contact-set-img {
    max-width: 100%;
  }

  .footer-row {
    width: 100%;
  }

  ul.footer-row.footer-social-icn {
    width: 100%;
    flex-direction: row;
  }

  .footer-row li:first-child {
    padding: 5px 0 0;
  }

  .footer-row {
    flex-direction: column;
    text-align: center;
  }

  .footer-row li:last-child {
    padding-right: 0;
  }

  .footer-row li:last-child a {
    border: none;
    padding: 0;
    margin-bottom: 3px;
    margin-top: 1px;
    display: inline-block;
  }

  .quote-timeline .quote-timeline--wrap {
    width: 408px;
  }

  .quote-timeline .quote-timeline--wrap li:before {
    width: 164px;
  }

  .notify_subSettings {
    flex-wrap: wrap;
  }

  .notify_subSettings .custom-checkbox {
    margin-bottom: 12px;
    flex: 0 0 100%;
  }

  .leadDetail_form.card-body .form-group {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .bookingResp_table table.table,
  .bookingResp_table table.table tbody,
  .bookingResp_table table.table tr td {
    display: inline-block;
    position: relative;
    max-width: 100%;
  }

  .bookingResp_table table.table tbody tr {
    display: flex;
    flex-wrap: wrap;
  }

  .bookingResp_table table.table.table-lg td {
    flex-grow: 1;
    max-width: 100%;
    width: 50%;
  }

  .bookingResp_table table.table.table-lg tbody td:first-child {
    flex: 0 0 60px;
    width: 60px;
  }

  .bookingResp_table table.table.table-lg td.booking--date {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% - 60px);
  }

  .no--contacts {
    background-color: #fff;
    min-height: 341px;
  }

  .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 15px;
  }

  .tab-section .ant-tabs-tab {
    font-size: 18px;
  }

  .button-align-center {
    justify-content: center
  }

  .compose-head>div {
    flex-wrap: wrap;
    align-items: center;
  }

  .compose-head h4 {
    font-size: 15px;
  }

  .email-from {
    margin-left: 0;
    flex: 1 1;
  }

  .email-from ul span {
    width: 100%;
    min-width: inherit;
  }

  .email_from.email-from ul li {
    flex-wrap: wrap;
  }

  .email-from ul li strong {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
  }

  .attachment_box {
    display: flex;
  }

  .wk-pdf {
    /* flex: 1; */
    display: inline-grid;
  }

  .email-from {
    width: 100%;
    flex: inherit;
  }
}

@media screen and (max-width: 576px) {
  .create-btns .btn {
    font-size: 8px;
    font-weight: bold;
    padding: 5px 6px !IMPORTANT;
    border-width: 2px;
  }

  .create-btns {
    gap: 5px;
  }

  .email-btn {
    padding: 3px;
  }

  .top-btn-left {
    gap: 3px;
  }

  /* .booking--location,
  .booking--amount,
  .booking--org {
    max-width: 100% !important;
    flex-basis: 100%;
  } */
  .no--contacts h5 {
    font-weight: bold;
    word-break: break-word;
    text-align: center;
  }

  #rc-tabs-0-panel-1 .tab-header button {
    margin: 0 !important;
  }

  .tab-header {
    position: relative;
    right: inherit;
    top: inherit;
    margin-bottom: 10px;
  }

  .card-header_btns.d-flex {
    justify-content: flex-start !important;
  }

  .sub-term-cond {
    margin-bottom: 145px;
  }

  .plan-descptn>p {
    min-height: 63px;
  }

  .subscription-tab-price {
    font-size: 22px;
  }

  .subs-select-price {
    margin-left: 14px;
  }

  .subs-select-price .subs-price {
    font-size: 21px;
    width: 111px;
  }

  .subscription-duration {
    margin-top: 20px;
    padding: 25px 10px;
  }

  .subs-plan-duration {
    padding: 25px 18px;
  }

  .quote-bizzlogo--name {
    align-items: flex-start;
    flex-direction: column;
  }

  .quote-bizzname {
    padding-left: 0;
    margin-top: 20px;
  }

  .quote-timeline .quote-timeline--wrap li span em {
    font-style: normal;
    display: block;
  }

  .leadDetail_form.card-body .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .page-title .title--col .title {
    max-width: 300px;
  }

  .contacts--table tbody td.contact--name {
    /* max-width: 176px;*/
  }

  .footer-col_left,
  .footer-col_right {
    text-align: center;
    flex: 0 0 100%;
  }

  .footer-col_right .social-btns {
    justify-content: center;
    margin-top: 17px;
  }

  .footer--nav {
    justify-content: center;
  }

  .modal-sm {
    max-width: 100%;
  }

  .quote-timeline .quote-timeline--wrap {
    width: 271px;
    margin-bottom: 63px;
  }

  .quote-timeline .quote-timeline--wrap li:before {
    width: 96px;
  }

  .quote-timeline .quote-timeline--wrap li span {
    width: 100px;
    font-size: 13px;
    right: -42px;
    white-space: normal;
    line-height: 16px;
  }

  .quote-timeline .quote-timeline--wrap {
    margin-bottom: 84px;
  }

  .subscribe--plans .selectgroup {
    margin-right: -10px;
  }

  .subscribe--plans .selectgroup .selectgroup-item {
    margin: 0 10px 10px;
  }

  .hide {
    display: none !important;
  }

  .paypal-text-msg {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 416px) {
  .categorySlect {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 375px) {
  .title-elems .cstselectmin-w {
    min-width: 120px;
  }

  .page-title .title--col .title {
    max-width: 280px;
  }

  .select-floating-label.css-1wa3eu0-placeholder {
    padding: 0 3px 0 5px;
    margin: 0;
    left: 6px;
    top: -8px;
    min-width: 100px;
    background-color: #fff;
  }

  .site-footer .col-12,
  .site-footer .container {
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .quote-timeline .quote-timeline--wrap {
    width: 234px;
  }

  .quote-timeline .quote-timeline--wrap li span {
    width: 80px;
    font-size: 0.76rem;
    right: -32px;
  }
}

@media screen and (max-width: 320px) {
  .quote-total-row .qt-fees {
    flex: 0 0 32%;
  }

  .qt-qttotal {
    width: auto;
    flex: 0 0 68%;
    min-width: 100px;
  }

  .create-quote-buttons .btn {
    margin: 0 2px;
  }

  .title-elems {
    padding: 10px 9px;
  }

  .btn {
    padding: 0.47rem 10px;
  }

  .title-elems .cstselectmin-w {
    min-width: 100px;
  }

  .page-title .title--col .title {
    max-width: 250px;
  }

  .select-floating-label.css-1wa3eu0-placeholder {
    padding: 0 3px 0 5px;
    margin: 0;
    left: 6px;
    top: -8px;
    min-width: 95px;
    background-color: #fff;
  }

  .css-douzob-ValueContainer {
    padding: 0 11px !important;
  }

  .select-floating-label.css-1wa3eu0-placeholder+div {
    margin-left: 0;
  }

  .modal-body {
    padding: 1rem 0.5rem 0;
  }
}

.appoitment-form p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.appoitment-form .modal-body {
  padding-top: 0;
}

/* .Dlt-x {
  display: none;
}
@media screen and (max-width: 767px) {
  .Dlt-x {
    display: block;
  }
  .delete-x {
    display: none;
  }
} */

.css-1wa3eu0-placeholder {
  font-size: 14px;
}

.paypal-text-msg1 {
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  margin: 0 auto 24px;
}

.paypal-text-msg1 strong {
  font-weight: 700;
  color: #98100F;
}

.payment-modal-testing .modal-dialog {
  max-width: 660px;
}

.custom-link-text {
  color: #817f80;
}

.custom-link-text:hover {
  color: #817f80;
  text-decoration: underline;
}

.attachment_box {
  width: auto;
  height: auto;
  display: inline-flex;
  background: #eaeaea;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.attachment_box span {
  font-size: 12px;
}

.attachment_box p {
  margin: 0;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment_box img {
  opacity: 0.8;
  height: 20px;
  margin-right: 5px;
}

.email-from .input-box {
  position: relative;
}

.email-from .input-box label {
  position: absolute;
  left: 0;
  top: 10px;
}

.email-thread-wrap {
  margin-bottom: 15px;
}

.email-content .table-responsive {
  max-height: 500px;
}

.events {
  margin-bottom: 25px;
  position: relative;
}

.events-heading {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}

.events-heading::before {
  height: 16px;
  width: 16px;
  position: absolute;
  left: 0;
  content: '';
  background: #30cdcc;
  border-radius: 2px;
  top: 4px;
}

.events-heading h4 {
  font-size: 20px;
  padding-right: 100px;
  margin-bottom: 3px;
  word-break: break-all;
}

.events span img,
.events h5 img {
  height: 25px;
  opacity: 0.7;
  margin-right: 3px;
}

.events h5 {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  max-height: 570px;
}

.custom-cursor {
  cursor: pointer;
}

.email-content table tbody .custom-email-tr:hover {
  background: #EEEEEE;
}

.action-btn.wkicon img {
  height: 13px;
  top: 0;
}

.wk-custom-label .custom-control label {
  margin: 0;
}

.wk-custom-label .custom-checkbox .custom-control-label::before {
  position: absolute;
  top: 5px;
  left: -20px;
}

.custom-link-text-new {
  white-space: nowrap;
  overflow: hidden;
  display: inherit;
  max-width: 550px;
  text-overflow: ellipsis;
}

.ellipss-text {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-content .table.wktable td,
.email-content .table.wktable th {
  line-height: 25px;
  white-space: nowrap;
}

.list-icons-div {
  width: 30px;
  max-width: 30px;
  display: inline-block;
}

.w30 {
  width: 30px;
  max-width: 30px;
}

.email-title .btn {
  padding: 7px 20px 7px 13px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  background: #fff;
  position: relative;
  text-decoration: none;

}

.email-title .btn:hover {
  background-color: #eee;
}

.email-title .btn::after {
  border: solid #343a40;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  content: '';
  right: 13px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 14px;
  transition: all 0.3s ease-in-out 0s;
}

.email-title .btn[aria-expanded="false"]::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 10px;
}

.email-title .btn[aria-expanded="true"] {
  background: #00cdcb;
  color: #fff !important;
}

.email-title .btn[aria-expanded="true"]::after {
  border-color: #fff;
}

.card-outer {
  margin-bottom: 10px;
  background: #fff;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 0;
  top: -5px;
  z-index: 9;
}

.action-button button {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
}

.action-button button img {
  height: 20px;
  opacity: 0.7;
}

.action-button button:hover {
  background: #fb9a00;
  border-color: #fb9a00;
}

.action-button button:hover img {
  filter: brightness(0) invert(1);
}

.wk-input input.floating-input {
  padding: 10px;
  height: 35px;
}

.wk-input .react-datepicker-wrapper input {
  border: 0.5px solid rgb(177 177 177 / 50%);
  padding: 6px 10px;
  width: 100%;
  height: 35px;
}

.wk-input .saveBtn {
  height: 35px;
  padding: 5px 26px;
}

.wk-input h6 {
  font-size: 14px;
  color: var(--header-text);
  font-weight: 600;
}

.google-events .modal-header {
  padding-top: 0;
}

td.task--todo {
  color: #4a90e2;
}

.table-action .action-btn img {
  height: 25px;
  transform: rotate(-135deg);
}

.tab-section .ant-tabs {
  height: 100%;
}

.detail_wrap {
  padding-left: 36px;
  max-height: 150px;
  overflow: auto;
}

.reminder_detail_wrap {
  max-height: 80px;
  overflow: auto;
}

.inner_detail {
  border-bottom: 1px solid #eee;
  padding: 10px 0px;
}

.inner_detail h6 {
  margin-bottom: 4px;
}

.events span.icn {
  display: inline-block;
  min-width: 30px;
  margin-right: 10px;
  margin-left: -5px;
  vertical-align: top;

}

.inner_detail:last-child {
  border: none;
}

.events-heading {
  padding-left: 36px;
}

.events .desc {
  width: calc(100% - 45px);
  display: inline-block;
  vertical-align: top;
  word-break: break-word;
}

.token-error-anchor {
  color: #4a4a4a;
  font-size: 14px;
  text-decoration: underline;
}

.token-error-anchor:hover {
  color: #4a4a4a;
}

.underline-text {
  text-decoration: underline;
}

.google-login-btn {
  background: transparent;
  padding: 0;
  border: none;
}

.google-login-btn:focus {
  outline: none;
}

.google-login-btn img {
  max-height: 60px;
}

.new-lead-timeline .timeline_row .timeline-cols.completed span:before {
  border-left: 16px solid #D6F5F5;
}

.line-item-del .close-icn svg {
  fill: #DB0200 !important;
}

.floating-label .css-yk16xz-control {
  border-radius: 6px !important;
  height: 44px !important;
}

.floating-label .css-11aao21-allyText {
  border-radius: 6px;
}

.new-lead-timeline .timeline_row .timeline-cols em {
  align-items: center;
}

.timeline-cols.completed .d-none.d-lg-flex p,
.timeline-cols.active .d-none.d-lg-flex p {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  padding: 0px !important;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-color: #30cdcc;
  margin-right: 10px;
  color: #fff;
}

.timeline-cols .d-none.d-lg-flex p {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  padding: 0px !important;
  background-color: #fff;
  margin-right: 10px;
  border: 1px solid #30cdcc;
  color: #30cdcc;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.close:focus {
  outline: 0px !important;
}

.table input {
  border-radius: 6px;
}

.dragable--card .title {
  border-bottom: 1px solid #E9E9EC;
  padding-bottom: 10px;
}

.dragable--card p.mb-0.quote-last-update {
  border-top: 1px solid #E9E9EC;
  padding-top: 10px;
}

.dragable--card.expired .info p:first-child,
.dragable--card.completed .info p:first-child,
.dragable--card.lost .info p:first-child {
  border-bottom: 1px solid #E9E9EC;
  padding-bottom: 10px;
  margin-bottom: 10px !important;
}

.dragable--card.expired .info p:nth-child(2n),
.dragable--card.completed .info p:nth-child(2n),
.dragable--card.lost .info p:nth-child(2n) {
  color: #818186;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dragable--card .info p:last-child {
  text-align: end;
}

/* -------------------new added------------ */
.new-input {
  font-size: 14px;
  padding: 12px 16px;
  border: 0.5px solid rgb(177 177 177 / 50%);
  color: #817f80;
  font-weight: 500;
  border-radius: 0;
  cursor: text;
  width: 100%;
  background: #fff !important;
}

select.new-input,
input.new-input {
  height: 44px;
}

.bold-text {
  font-weight: bold;
}

.infoICon .floating-select.css-2b097c-container {
  width: 90%;
}

/* .infoICon .tool-tip {
  position: absolute;
  top: 42%;
  right: -2%;
} */

.col-lg-3 .main-card.create-qoute--cards .card.main-card--collapse.show .field-text:first-child {
  color: #121213 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.main-card.create-qoute--cards .card.main-card--collapse.show label {
  /* color: var(--text-colors-black-500, #121213); */
  font-size: 14px;
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
}

.main-card.create-qoute--cards .card.main-card--collapse.show label strong {
  font-weight: bold !important;
}

.main-card.create-qoute--cards .card.main-card--collapse.show .field-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #121213;
}

.main-card.create-qoute--cards .card.main-card--collapse.show .field-text strong {
  font-weight: 700;
}

/* .leads-col_body .dragable--card .info p:last-child{
  border-top: 1px solid #E9E9EC;
  margin-top: 10px;
  padding-top: 10px;
  font-weight: 400;
} */
.no--contacts--note .text-secondary p {
  text-align: center;
  font-size: 14px;
  color: #6B6B6B;
}

.empty-leads p {
  color: #4A4A4A;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.4px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: #f5f6fe;
  padding-top: 56px;
  overflow-x: hidden;
}

.app-header {
  background: #111717;
  height: 80px;
  display: flex;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /*  z-index: 1030; */
}


.justify-content-between {
  justify-content: space-between !important;
}

.app-sidepanel.sidepanel-visible {
  left: 0;
}

.app-sidepanel {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  left: 0;
  top: 80px;
  font-size: 0.875rem;
}

.app-sidepanel .sidepanel-inner {
  position: relative;
  z-index: 10;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 15px;
  width: 72px;
  height: 100%;
}

.flex-column {
  flex-direction: column !important;
}

.d-flex {
  display: flex !important;
}

.app-sidepanel .app-nav-main {
  overflow-y: hidden;
}

.app-nav {
  color: #5d6778;
  display: block;
  padding: 0;
  width: 100%;
  background-color: #151B22;
  overflow: hidden;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.app-nav .app-menu {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.app-nav .nav-item {
  display: block;
  color: #fff;
  background: #151B22;
  height: 48px;
  margin-bottom: 12px;
}

.app-nav .nav-link.active {
  color: #fff;
  background: #ffffff2e;
  border-left: 5px solid #FB9A00;
  font-weight: bold;
  font-size: 10px;
}

.app-nav .nav-link {
  font-size: 14px;
}

.app-nav .nav-link {
  display: block !important;
  padding: .8rem 0rem;
  color: #fff;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.app-nav .nav-icon .bi {
  width: 24px;
  height: 24px;
}

.app-branding .app-logo {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.app-branding .app-logo span {
  color: #30CDCC;
}

.app-header-inner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
}

.profile-dropdown img {
  width: 36px;
  height: 36px;
  border-radius: 36px;
}

.profile-dropdown {
  display: flex;
  align-items: center;
  justify-content: end;
}

.profile-dropdown .name {
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  padding-left: 20px;
}

.dropdownIcon svg {
  width: 26px;
  height: 26px;
  padding-left: 10px;
  fill: #fff;
}

.nav-item .nav-link .nav-icon {
  float: left;
  width: 100%;
  margin-bottom: 8px;
}

.card-header.py-4.d-flex.justify-content-between.align-items-center.notifications .card-header_btns .btn:active svg,
.card-header.py-4.d-flex.justify-content-between.align-items-center.notifications .card-header_btns .btn:focus svg,
.card-header.py-4.d-flex.justify-content-between.align-items-center.notifications .card-header_btns .btn:hover svg {
  fill: #fff;
}

.card-header.py-4.d-flex.justify-content-between.align-items-center.notifications .card-header_btns .btn svg {
  fill: var(--danger);
}

.profile-dropdown button.btn.dropdown-toggle {
  color: #fff;
  height: auto !important;
  min-height: auto !important;
  max-height: fit-content;
}

.profile-dropdown span {
  font-size: 14px;
  color: #fff;
}

.plan-status a.text-link {
  color: #fff !important;
}

.profile-dropdown .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.6em;
  vertical-align: 0.055em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}

a.btn.btn-primary.d-none.d-lg-block.back svg {
  color: #818186;
}

button.btn.btn-danger.mr-15.trash:active svg {
  fill: #fff !important;
}

button.btn.btn-danger.mr-15.trash svg {
  padding-right: 5px;
}


.no--contacts--note .text-secondary p {
  text-align: center;
  font-size: 14px;
  color: rgba(107, 107, 107, 0.75);
  line-height: 20px;
}

.empty-leads p {
  color: #4A4A4A;
  font-weight: 700;
  font-size: 16px;
  line-height: 18.4px;
}

.table-striped .qt-activity-name .titleName strong {
  margin-bottom: 0px;
}

.table-striped .qt-activity-name .titleName strong h5 {
  margin-bottom: 0px;
  font-family: var(--font-family-sans-serif);
  font-weight: 700;
}

.email_Btns h5 {
  font-size: 16px;
  color: #818186;
}

a.btn.btn-dark.mr-15.Cancel svg,
button.btn.btn-dark.mr-15.Cancel svg {
  padding-right: 5px;
}

.mycustom-alert .swal2-cancel {
  background-color: #979797 !important;
  color: #fff !important;
  border: 1px solid #979797 !important;
}

.mycustom-alert .swal2-cancel:hover {
  color: #fff !important;
  background-image: none !important;
}

button.btn.btn-danger.mright.mr-15 svg {
  padding-right: 5px;
}

.form-group input {
  border-radius: 6px;
  border: 1px solid #C2C2C5;
}

.form-group .floating-select.css-2b097c-container:focus-visible,
.form-group .floating-select.css-2b097c-container:focus,
.form-group textarea:focus-visible,
.form-group textarea:focus,
.form-group input:focus-visible,
.form-group input:focus {
  border: 1px solid #ffc107 !important;
  outline: none !important;
}

.css-yk16xz-control:focus-visible,
.css-yk16xz-control:focus,
.css-douzob-ValueContainer:focus-visible,
.css-douzob-ValueContainer:focus {
  border: 0px !important;
  outline: none !important;
}

.css-1pahdxg-control input:focus-visible {
  border: none !important;
  outline: none !important;
}

.modal-body input.new-input {
  border-radius: 6px !important;
}

.modal-body input.new-input:focus-visible,
.modal-body input.new-input:focus {
  border: 1px solid var(--primary) !important;
  outline: none !important;
}

.form-row input.new-input {
  border-radius: 6px !important;
}

.form-group textarea {
  border-radius: 6px !important;
}

.swal2-actions button.swal2-cancel.cancel-alert-note.primeryBtnO.swal2-styled {
  background-color: var(--primary) !important;
  color: #fff !important;
  border-color: var(--primary) !important;
}

button.btn.btn-danger.mr-15.trashbtn,
button.btn.btn-dark.mr-15.trashbtn {
  display: flex !important;
  padding: 10px 18px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5) !important;
  color: #DB0200 !important;
  background-color: transparent !important;
}

@media screen and (max-width:600px) {
  .deleteBtn {
    margin-left: 2px;
    width: 125px;
  }
}

button.btn.btn-danger.mr-15.trashbtn svg,
button.btn.btn-dark.mr-15.trashbtn svg {
  fill: #DB0200 !important;
}

button.btn.btn-danger.mr-15.trashbtn:hover,
button.btn.btn-dark.mr-15.trashbtn:hover {
  color: #DB0200 !important;
  border-color: #DB0200 !important;
}

.btn.btn-secondary.mr-15.back svg,
.btn.btn-primary.d-none.d-lg-block.back svg {
  color: #fff !important;
}

input.form-control.mr-2 {
  border-radius: 6px !important;
}

.d-flex.notificationDropdown {
  margin-top: 10px;
  align-items: center;
}

.d-flex.notificationDropdown .col-md-6 {
  display: flex;
  padding: 0;
  margin-top: 10px;
  gap: 2rem;
  align-items: center;
}

.d-flex.notificationDropdown .col-md-6 label {
  font-size: 14px;
  color: #4A4A4A !important;
  display: flex;
  align-items: center;
}

.d-flex.notificationDropdown .col-md-6 select {
  width: 115px;
  height: 34px;
  background-color: #fff;
  border: 1px solid #BCBCBC;
  padding: 0 5px;
  border-radius: 6px;
}

.d-flex.notificationDropdown .col-md-6 select:focus,
.d-flex.notificationDropdown .col-md-6 select:active {
  border: 1px solid #FB9A00;
}

.d-flex.notificationDropdown .col-md-6.p-0 {
  text-align: end;
  display: block;
  margin-top: 0;
}

.breadcrumb li.breadcrumb-item.active {
  color: var(--text-colors-black-500, #121213);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.formbox .line-item--row.line-item--row2 .form-group.col-xl-2.col-lg-3.col-5.selectBOx.USD {
  align-items: baseline !important;
}

.formbox .line-item--row.line-item--row2 .form-group.col-xl-2.col-lg-3.col-5.selectBOx.USD .floating-label.m-0 {
  margin-right: 10px !important;
}

.formbox .quote--create-form .input-edit-btn input {
  color: #817f80;
  font-size: 14px;
  line-height: 16.41px;
  border: 0.5px solid rgb(177 177 177 / 50%);
  font-weight: 500;
  height: 44px;
  padding: 12px 16px;
}

.main-card.reviewQuote--ftr.h-auto .card {
  background-color: transparent;
}

.col-lg-3 .create-qoute--cards .card .form-group .field-text {
  color: var(--text-colors-grey-500, #818186) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.create-qoute--cards .card-header small.paymentSetupText,
.create-qoute--cards .card-header small.invoiceNote {
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.deposit-reqr>p {
  color: var(--text-colors-black-500, #121213);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deposit-reqr .form-group p {
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.dragable--card p.mb-0.revenueInfo {
  text-align: left;
  border-top: 1px solid #E9E9EC;
  padding-top: 10px;
  margin-top: 10px;
}

.dashboard-business-card.card.main-card--collapse.show.snapshot .card-header.py-4.d-flex.justify-content-between.align-items-center,
.dashboard-business-card.card.main-card--collapse.show.snapshot {
  background-color: transparent !important;
}

.snapshot--box_divider {
  visibility: hidden;
}

.dashboard-business-card.card.main-card--collapse.show.snapshot .card-header .css-yk16xz-control {
  height: 40px !important;
  width: 168px;
  border-radius: 40px !important;
}

.dashboard-business-card.card.main-card--collapse.show.snapshot .css-1pahdxg-control {
  height: 40px !important;
  width: 168px;
  border-radius: 40px !important;
}

.message-sender-wrap .email_Btns h5 {
  color: #6B6B6B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

label strong {
  font-weight: 400 !important;
}

.floating-label input::placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.task-view-filter.dashborardtaskbtn.css-2b097c-container .css-yk16xz-control {
  height: 40px !important;
  border-radius: 40px;
  width: 140px;
}

.task-view-filters.dashborardtaskbtn.css-2b097c-container .css-yk16xz-control {
  height: 40px !important;
  border-radius: 40px;
  width: 140px;
}

.task-view-filter.dashborardtaskbtn.css-2b097c-container .css-yk16xz-control .css-1uccc91-singleValue {
  color: #121213 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.task-view-filters.dashborardtaskbtn.css-2b097c-container .css-yk16xz-control .css-1uccc91-singleValue {
  color: #121213 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

a.btn.btn-danger.ClearList {
  border-radius: 40px;
  border-color: #DB0200 !important;
  display: inline-flex;
  padding: 8px 20px 8px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contactsheader p,
.bookingheader p,
.InvoicesHeader p,
.Contractsheader p,
.Quotesheader p,
.leadheader p {
  color: #818186;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Contactsheader p span,
.bookingheader p span,
.InvoicesHeader p span,
.Contractsheader p span,
.Quotesheader p span,
.leadheader p span {
  color: #121213;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 8px;
}

.dragable--card:hover {
  border: 1px solid #FB9A00;
}

.dragable--card .info b {
  color: #121213;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dragable--card.expired:hover {
  border-top: solid 4px #817f80;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}

.dragable--card.completed:hover {
  border-top: solid 4px #30cdcc;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}

.dragable--card.lost:hover {
  border-top: solid 4px #9a1710;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
}

.leads-container .leads-container_row .leads-col .leads-col_header .header--web h4 {
  color: #121213 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px !important;
}

div#contactDetail {
  padding-top: 20px;
}

.add-fee_discount a {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  border: 1px solid #C2C2C5;
  color: #30CDCC;
  border-radius: 6px;
  width: 177px;
}

.add-fee_discount a:hover {
  border: 1px solid #30CDCC !important;
  color: #30CDCC !important;
  text-decoration: none;
}

.add-fee_discount span {
  margin: 0px 20px;
}

.formbox .line-item--add.mb-4 a.btn.btn-secondary {
  display: flex;
  width: 350px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #C2C2C5;
  color: #30CDCC;
}

.formbox .line-item--add.mb-4 a.btn.btn-secondary:focus,
.formbox .line-item--add.mb-4 a.btn.btn-secondary:active,
.formbox .line-item--add.mb-4 a.btn.btn-secondary:hover {
  border: 1px solid #30CDCC !important;
  color: #30CDCC !important;
}

.formbox .line-item--detail .add-fee-wrap .add-fee_discount {
  display: flex;
  align-items: center;
}

.formRequirements .card-header p.mt-3 {
  color: #818186;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.formRequirements .card-header p.mt-3 a strong {
  color: #4A90E2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.formRequirements .card-header p.mt-3 a {
  color: #4A90E2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.formRequirements .deposit-reqr p strong {
  color: #121213;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.create-qoute--cards .card-body.pt-0.pl-4.pr-4 textarea.form-control {
  border-radius: 6px;
}

.create-qoute--cards .card-body.pt-0.pl-4.pr-4 textarea.form-control::placeholder {
  color: #ABABAE;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.OptionalText {
  color: #818186;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}

.notesViewQuote {
  font-size: 1rem;
  color: #818186;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PreviewSectiontab .main-card.create-qoute--cards .card.main-card--collapse.show label span {
  float: right;

}

.PreviewSectiontab .main-card.create-qoute--cards .card.main-card--collapse.show label span a {
  color: var(--support-colors-link-500, #4A90E2);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: bold !important;
  line-height: 20px;
}

.notesdeposit {
  color: #818186;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notesdeposit b {
  color: #818186;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: 16px;
}

.qt-fees {
  font-size: 16px;
  color: #121213;
}

#TermsCondCollapse0 .card-body.px-4 {
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.PreviewSectiontab .card.main-card--collapse.show label {
  color: var(--text-colors-grey-500, #818186) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Dashboard */

.DashboardFixedheader section.page-title.contact--header.Dashboard-header {
  background-color: rgba(242, 242, 242, 0.5);
  border-bottom: 1px solid var(--Stroke, #DAE0E0);
}

.main-site.fixed--header.DashboardFixedheader.fixed--elements section.page-title.contact--header.Dashboard-header {
  background-color: #fff !important;
}

.fixedheader section.page-title.contact--header.Dashboard-header {
  border-bottom: 0;
}

.Dashboard-header button#dropdownMenuButton {
  display: flex;
  padding: 10px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: #4A90E2;
}

.Dashboard-header button#dropdownMenuButton:hover {
  border: 1px solid var(--text-colors-light-grey-600, #4A90E2);
  color: #4A90E2;
}

.Dashboard-header .dropdown-toggle::after {
  display: none;
}

.task-view-filter.dashborardtaskbtn.css-2b097c-container .css-1pahdxg-control {
  height: 40px !important;
  border-radius: 40px !important;
  width: 140px !important;
}

.task-view-filters.dashborardtaskbtn.css-2b097c-container .css-1pahdxg-control {
  height: 40px !important;
  border-radius: 40px !important;
  width: 140px !important;
}

.viewtext {
  color: var(--text-primary, #121213);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.sticky-table .dashboardhead th {
  background-color: #f9f9f9 !important;
  border: 1px solid #DAE0E0 !important;
  color: #818186 !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 120%;
  text-transform: uppercase;
  height: 48px !important;
  padding: .75rem 1.5rem !important;
}

.DashboardFixedheader .tab-section .ant-tabs {
  padding: 0 !important;
}

.DashboardFixedheader .tab-section .ant-tabs .ant-tabs-nav {
  padding: 1.5rem 1.5rem 0 1.5rem !important;
}

.DashboardFixedheader #NotificationsCollapse h2,
.DashboardFixedheader .ant-tabs-nav-list .new-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.DashboardFixedheader #NotificationsCollapse .task-countt,
.DashboardFixedheader .ant-tabs-nav-list .new-tab span {
  display: inline-flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #EBEBEB;
  color: var(--text-primary, #121213);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 2.8px 6.67px;
}

.dashboard-business-card.card.main-card--collapse.show.snapshot .card-header.py-4.d-flex.justify-content-between.align-items-center,
.dashboard-business-card.card.main-card--collapse.show.snapshot .card-body.pt-1 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/*lead */
section.page-title.contact--header.leadheader .btn-primary {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.floating-select .css-douzob-ValueContainer .css-1wa3eu0-placeholder,
.floating-label .css-douzob-ValueContainer .css-1wa3eu0-placeholder {
  color: var(--text-colors-grey-500, #818186);
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
}

.floating-label .css-douzob-ValueContainer .css-1ot2r5b-Input input {
  background: transparent !important;
  position: relative;
  /* top: px; */
}

.addOptional {
  display: flex;
  width: 350px;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: #30CDCC !important;
}

.addOptional:focus,
.addOptional:active,
.addOptional:hover {
  border: 1px solid var(--text-colors-light-grey-600, #30CDCC) !important;
  color: #30CDCC !important;
}

/* Quotes */

.Quotesheader .btn-primary {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.addbasicquoteheader button.btn.btn-secondary.mr-15 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasicquoteheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasicquoteheader button.btn.btn-secondary.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasicquoteheader button.btn.btn-dark.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px
}

.addbasicquoteheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addQuotesheader button.btn.btn-dark.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addQuotesheader button.btn.btn-secondary.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--primary-colors-yellow-500, #FB9A00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

a.btn.btn-dark.mr-15.back {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addQuotesheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.viewQuoteheader a.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.viewQuoteheader a.btn.btn-primary {
  display: flex;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-danger {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--support-colors-wrong-500, #DB0200);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-danger:active,
.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-danger:hover {
  color: var(--support-colors-wrong-500, #DB0200);
  border: 1px solid #DB0200;
}

.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-secondary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--primary-colors-yellow-500, #FB9A00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-secondary:active,
.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-secondary:hover {
  color: var(--primary-colors-yellow-500, #FB9A00);
  border: 1px solid #FB9A00;
}

.main-card.reviewQuote--ftr.h-auto .rvwqt-btns a.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentQuotesheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentQuotesheader button.btn.btn-secondary.mr-15 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #FB9A00;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentQuotesheader button.btn.btn-primary.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.notificationDropdown .btn-secondary {
  display: inline-flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid #FB9A00;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.email_Btns button.btn.btn-primary.float-sm-left.float-md-left.float-lg-left.float-xl-left.float-none.mt-2 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.rvwqt-btns.disabled-button-state {
  display: flex;
}

/* Contract */

.Contractsheader .btn-primary {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.addbasiccontractheader button.btn.btn-dark.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasiccontractheader button.btn.btn-secondary.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasiccontractheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addcontractheader button.btn.btn-dark.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addcontractheader button.btn.btn-secondary.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--primary-colors-yellow-500, #FB9A00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addcontractheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.viewcontractheader a.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.viewcontractheader a.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentcontractheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentcontractheader button.btn.btn-secondary.mr-15 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #FB9A00;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentcontractheader button.btn.btn-primary.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasiccontractheader button.btn.btn-secondary.mr-15 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasiccontractheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

/* Invoice */

.InvoicesHeader .btn-primary {
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.addbasicInvoiceheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addbasicInvoiceheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addInvoiceheader button.btn.btn-dark.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addInvoiceheader button.btn.btn-secondary.mr-15.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--primary-colors-yellow-500, #FB9A00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.addInvoiceheader button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.viewinvoiceheader a.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.viewinvoiceheader a.btn.btn-primary {
  display: flex;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentInvoiceheader button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentInvoiceheader button.btn.btn-secondary.mr-15 {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #FB9A00;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.sentInvoiceheader button.btn.btn-primary.d-none.d-lg-flex {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.dragable--card.lost .info span {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--support-colors-wrong-50, #F8CCCC);
  color: var(--support-colors-wrong-500, #DB0200);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dragable--card.expired .info span {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #817f80;
  color: #fff;
}

.dragable--card.completed .info span {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--support-colors-success-green-50, #CDE6DF);
  color: var(--support-colors-success-green-500, #05825D);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #DB0200 !important;
  border-color: #DB0200 !important;
  background-color: transparent !important;
}

.total_row .field-text.text-right.mb-0.mr-2 {
  text-align: left !important;
  color: var(--text-colors-grey-500, #818186);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.total_row .d-flex.align-items-center .feetotal-amount {
  color: var(--text-colors-black-500, #121213);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.total_row .d-flex.align-items-center p.m-0.pl-2.d-none.d-lg-flex {
  color: var(--text-colors-grey-500, #818186);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.total_row .col-5 .d-flex.align-items-center {
  justify-content: space-between;
}

.create-quote-buttons a.btn.btn-primary.btn-secondary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  color: var(--primary-colors-yellow-500, #FB9A00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.create-quote-buttons button.btn.btn-primary {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: var(--text-colors-white-500, #FFF);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.create-quote-buttons {
  display: flex;
  justify-content: center;
}

.listLeadbody .dragable--card span {
  font-size: 12px !important;
  cursor: grab !important;
  color: #121213 !important;
  word-break: break-all !important;
  font-weight: bold !important;
  display: block !important;
  margin-right: 10px;
  background-color: transparent !important;
  color: #121213 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

button.btn.btn-secondary.mr-15,
button.btn.btn-secondary.ml-15,
.btn.btn-secondary.d-none.d-lg-block {
  display: flex !important;
  padding: 14px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  color: #FB9A00;
  border: 1px solid var(--text-colors-light-grey-600, #C2C2C5);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

button.btn.btn-secondary.disable {
  color: #98100F !important;
}

button.btn.btn-secondary.disable:hover {
  border: 1px solid var(--text-colors-light-grey-600, #98100F) !important;
}

.task-view-filter .css-yk16xz-control,
.task-view-filters .css-yk16xz-control,
.taskdropdownfilter .css-yk16xz-control {
  height: 40px !important;
  border-radius: 40px !important;
  width: 160px !important;
}

.task-view-filter .css-1pahdxg-control,
.task-view-filters .css-1pahdxg-control,
.taskdropdownfilter .css-1pahdxg-control {
  height: 40px !important;
  border-radius: 40px !important;
  width: 160px !important;
}

a.btn.btn-primary.d-none.d-lg-block.back,
button.btn.btn-primary {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--primary-colors-yellow-500, #FB9A00);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

button.btn.btn-dark.mr-15 {
  display: flex !important;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 6px;
  background: var(--text-colors-light-grey-500, #E9E9EC);
  color: var(--text-colors-grey-500, #818186);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.title-elems .css-yk16xz-control {
  height: 44px !important;
}

.dashboard-business-card .card-header.py-4.d-flex.justify-content-between.align-items-center {
  padding-top: 22px !important;
}

textarea.new-input.floating-textarea {
  border-radius: 6px !important;
}

textarea.new-input.floating-textarea:focus-visible {
  border: 1px solid #ffc107 !important;
  outline: none !important;
}

.site-body .contact--header .btn {
  height: 54px !important;
  min-height: 54px !important;
  max-height: 54px !important;
  padding: 14px 18px;
  display: flex;
  align-items: center;
}

.site-body .contact-header .btn {
  padding: 14px 0px;
}

.site-body .Dashboard-header button#dropdownMenuButton {
  height: 44px !important;
  min-height: 44px !important;
  max-height: 44px !important;
}

.dashboard-task-table td {
  padding: .75rem 1rem !important;
}

.welcomeClosebtn {
  z-index: 1 !important;
}

.welcomeContibuebtn {
  display: flex;
  justify-content: center;
}

.headerplansdrop {
  display: flex !important;
  justify-content: end !important;
  align-items: center;
  width: 100%;
}

.app-branding {
  position: absolute;
  left: 2rem;
}

.errorValidationMessage {
  color: var(--danger) !important;
}

.line-item--loop .line-item--row .form-group.col-xl-2.col-lg-3.col-5.selectBOx.USD input {
  border: none !important;
  padding: 0 !important;
}

.line-item--loop .line-item--row .USD .d-flex.align-items-center {
  font-weight: 700 !important;
  font-size: 16px !important;
  text-align: center !important;
  display: inline-flex !important;
  align-items: center !important;
}

.line-item--loop .line-item--row .USD .d-flex.align-items-center p.m-0.d-none.d-lg-flex {
  margin-top: -7px !important;
}

.SelectPlanTermbtn {
  display: flex;
  justify-content: end;
  padding: 0;
}

.apply-coupon .totalduetoday input {
  height: 44px;
}

.dashboardhead th:nth-child(4) {
  border-right: 0px !important;
}

.dashboardhead th:last-child {
  border-left: 0px !important;
}

.dashboard-task-table .task--subject,
.dashboard-task-table .task--subject span {
  color: var(--info);
  font-weight: bold !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

.dashboard-task-table tbody td.task--todo {
  font-size: 14px;
  color: #818186 !important;
  font-weight: normal;
  line-height: 120%;
}

.task--status a:hover {
  text-decoration: none !important;
}

.bookingheader .title-elems .css-1pahdxg-control,
.bookingheader .title-elems .css-yk16xz-control {
  height: 54px !important;
}

.reviewQuote--ftr .rvwqt-btns {
  display: flex !important;
}

.InvoiceEmailbtngrp {
  display: flex !important;
}

.InvoiceEmailbtngrp .text-end {
  width: 100%;
}

.css-yk16xz-control .css-jlkhw4 input {
  position: relative;
  left: -2px;
  top: -7px;
  background-color: transparent !important;
}

/* Profile Two Factor */
.two-factor .li {
  list-style: none;
}

.two-factor .enable-date {
  margin: 15px 0 0 18px;
  color: #6B6B6B;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Terms & Conditions */
.term-and-condirion {
  padding-left: 20px;
}

.MenuList {
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  /* .site-body {
    padding-left: 40px;
  }

  .middle-section {
    padding-left: 40px !important;
  } */
}

@media screen and (max-width: 1270px) and (min-width: 768px) {
  .DashboardFixedheader section.page-title.contact--header.Dashboard-header {
    /* padding-left: 40px !important; */
  }

  .fixed--header .contact--header {
    /* padding-left: 40px !important; */
  }
}

.optional {
  color: var(--Text-Colors-Grey-500, #818186);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-left: 6px
}

.filetype {
  color: #817F80;
  font-family: Arial;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
}

.pdf-upload-div {
  width: 300px;
  height: 74px;
  flex-shrink: 0;
  background-color: rgba(242, 242, 242, 0.70);
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.pdf-upload-divBooking {
  margin-left: 20px;
}

.upload-file-title {
  color: #817F80;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  word-break: break-word;
}

.upload-file-titlesBooking {
  color: #00b7ff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  word-break: break-word;
}

.upload-file-title {
  color: var(--info);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 19px;
  word-break: break-word;
}

.upload-title {
  color: var(--info);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  word-break: break-word;
}

.upload-booking-file {
  color: var(--info);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 19px;
  word-break: break-word;
}

/* Error Box for File selection---- */

#errBox {
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: none;
  box-shadow: 0 0 0px 3000px #3935356e;
  transition: all ease 0.4s;
}

#errBox>img {
  /* background-color: #000;
  padding: 3px;
  mix-blend-mode: hard-light;
  border-radius: 50%;
  position: absolute;
  top: -12px;
  right: -12px; */
  position: absolute;
  top: -21px;
  right: -21px;
}

.disabled-button-style {
  pointer-events: none;
  cursor: none;
}

/* .errBoxHeading{
  display: flex;
  align-items: center;
} */

#errBox>div>div h6 {
  color: #817F80;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

#errBox>div>div h6 img {
  margin-right: 6px;
  margin-top: -5px;
}

#errBox>div>div dl {
  color: #817F80;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

#errBox>div>div dl dt {
  margin-bottom: 19px;
}

#errBox>div>div dl li {
  margin-left: 22px;
}

#errBox>div div:last-child {
  width: auto;
  text-align: end;
}

#errBox>div:last-child button {
  border: none;
  padding: 10px 25px;
  color: #FFF;
  background-color: var(--primary);
}

.email-modal-contain {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.email-modal-contain.modal-header {
  z-index: 99;
  background: #fff;
}

.tooltipIcon_file {
  height: 20px;
  width: 20px;
  z-index: 1;
  margin-left: 8px;
  cursor: pointer;
}

.Selectheading {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #121213;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

.importContactSelectheading {
  min-height: 49px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: end;
  word-break: break-all;
}

.updateTask {
  color: var(--info);
  font-weight: bold !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

.show-less-btn {
  border: none;
  background: transparent;
  /* margin: 20px 0; */
  color: var(--info);
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}

.show-less-btn:focus {
  outline: none;
}

.highlighted-note {
  font-size: 6px;
  color: #30CDCC;
  font-weight: bold;
  font-family: 'Helvetica Neue';
}

.beta-indicator {
  height: 24px;
  margin-left: 8px;
}

/* Wel-come screen ui updarte css */

.contacts-leads-title {
  /* font-family: Helvetica Neue; */
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #2D2D2D;
  margin-left: -16px;
}

.contacts-leads-points {
  margin: 10px 0 0;
}

.contacts-leads-points ul li {
  /* font-family: Lato; */
  font-size: 20px;
  /* font-weight: 600; */
  line-height: 33px;
  text-align: left;
  color: #136564;
}

.previous-btn {
  border: 1px solid #C2C2C5 !important;
  background-color: transparent !important;
  width: 105px;
  padding: 14px 18px;
  /* font-family: Helvetica Neue; */
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #FB9A00 !important;
}

.next-btn {
  width: 105px;
  padding: 14px 18px;
  /* font-family: Helvetica Neue; */
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

@media only screen and (min-width: 769px) {
  .screen-modal-content {
    min-height: 328px;
    flex-direction: row !important;
  }

  #welcome-screen {
    min-height: 760px;
  }
}

/* swope css */

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: transparent;
  opacity: 1;
  border: solid 1px var(--selected);
}

.swiper-pagination-bullet-active {
  background-color: var(--selected);
  border: solid 1px var(--selected);
}

/* .modal-body .tox-tinymce {
  height: 280px !important;
} */

.opacityHide {
  opacity: 0;
}

#root-portal .react-datepicker-popper {
  z-index: 2000 !important
}

@media (min-width: 768px) {

  /* For md breakpoint */
  .text-nowrap-md {
    white-space: nowrap;
  }

}

.custom-control-input:disabled~.custom-control-label::before {
  background-image: url(../images/disable-checkbox-icon.svg);
  background-size: 19px;
  background-position: 0px;
  width: 20px;
  height: 20px;
  border-radius: 0px;
}

.business-details-info {
  max-width: 200px;
}

/* .disable-inputs:disabled {
  cursor: no-drop;
} */

.custom-disabled {
  cursor: no-drop;
}

.label-disabled {
  cursor: no-drop !important;
}

.remove-focus:focus {
  outline: none !important;
  outline-offset: 0px !important;
}

@media (max-width: 375px) {
  .responsive-form-buttons {
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 100% !important;
  }

  .responsive-mobile-view-button {
    display: flex;
    justify-content: end !important;
    width: 100%;
  }

  .button-text-responsive-div {
    width: 100%;
    justify-content: space-between !important;
  }

  .responsive-copy0link-button {
    margin: 0px !important;
  }
}

.business-name-style {
  font-weight: 700;
  color: #121213;
}

@media only screen and (max-width: 900px) {
  .responsive-contact-form {
    flex-direction: column;
    gap: 0px !important;
  }
}

.custom-card-body-form {
  padding: 20px !important;
}

.contact-form-custom-gaps {
  column-gap: 40px;
  row-gap: 15px;
}